import React from "react";

const connectionHubContext = React.createContext<any>(null);

const useConnectionHubContext = () => {
  const context = React.useContext(connectionHubContext);
  if (context === null) {
    throw new Error("Error");
  }
  return context;
};

const connectionHubContextProvider = ({ children, value }: { children: React.ReactNode; value: any }) => {
  return <connectionHubContext.Provider value={value}>{children}</connectionHubContext.Provider>;
};

export { connectionHubContext, useConnectionHubContext, connectionHubContextProvider };