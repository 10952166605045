import React from "react";
import SubMenu from "../../Submenu/SubmenuApp";
import './RepeatTemplatePage.css';
import '../../shared/styles/GeneralPageStyles.css'
import { ReactComponent as RepeatTemplateIcon } from "../../DropDownMenu/SelectRepeatTemplate/static/repeatTemplate_icon.svg"
import TodosRepeatTemplate from "../../Todos/TodosRepeatTemplate";

interface RepeatTemplatePageProps {
    setIcon: Function
}

export default function RepeatTemplatePage({setIcon}: RepeatTemplatePageProps) {
    return (
        <div  className="main-container-view">
                <div className="submenu__container">
                    <SubMenu></SubMenu>
                </div>
                <div className="page-container">
                    <div className="page-info">
                        <RepeatTemplateIcon className="page-info__incoming-icon"/>
                        <h1 className="page-title page-title-incoming">Повторяющиеся задачи</h1>
                    </div>
                    <div>
                        <TodosRepeatTemplate setIcon={setIcon}/>
                    </div>
                </div>
            </div>
    )
}
