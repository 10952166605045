import { useState, useEffect } from "react";
import { ResetPassword } from "../../asyncActions/ResetPassword";
import { useNavigate } from "react-router-dom";
// import FooterPage from "../Footer/footer";


export default function ResetPasswordP(){
    const navigate = useNavigate();
    const [newPassword, setNewPassword] = useState('');
    const [ConfirmPassword, setConfirmPassword] = useState('');
    const [flag, setFlag] = useState(false);
    const [pushBtn, setPushBtn] = useState(true);
    const [errorPasswordUpperCase, setErrorPasswordUpperCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordLowerCase, setErrorPasswordLowerCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordDigitCase, setErrorPasswordDigitCase] = useState('error-change-email unhide-error-email');
    const [errorPasswordLength, setErrorPasswordLength] = useState('error-change-email unhide-error-email');
    const [errorPasswordNotMatch, setErrorPasswordNotMatch ] = useState('hide');
    const url = new URL(window.location.href);
    const userId = url.searchParams.get('UserId');
    const code = url.searchParams.get('Code');

    let regexpToLowerCase = /[а-яa-z]/gu // eslint-disable-line
    let regexpToUpperCase = /[А-ЯA-Z]/gu // eslint-disable-line
    let regexpToDigitCase = /[\p{Nd}]/gu // eslint-disable-line

    const sending = () => {
        const objResetPassword = {
            newPassword: newPassword,
            confirmPassword: ConfirmPassword,
            userId: userId,
            code: code
        }
        ResetPassword(objResetPassword);
        navigate('/login/'); 
    }
    
    /* eslint-disable-next-line */
    useEffect(() => {
        if ((newPassword === ConfirmPassword) && (newPassword !== '') && (ConfirmPassword !== '')) {
            setPushBtn(false)
        } else {
            setPushBtn(true)
            if ((ConfirmPassword !== newPassword) && (ConfirmPassword !== '')) {
                setErrorPasswordNotMatch('error-change-email unhide-error-email')
            } else {
                setErrorPasswordNotMatch('hide')
            }
        }
    }, [newPassword, ConfirmPassword]);

    function checkPassword(e) {
        setNewPassword(e.target.value)
        toLowerCase(e)
        toUpperCase(e)
        toDigitCase(e) 
        toLengthPassword(e)
    }

    function checkRepeatPassword(e) {
        setConfirmPassword(e.target.value)
        if ((e.target.value !== newPassword) && (e.target.value !== '')) {
            setErrorPasswordNotMatch('error-change-email unhide-error-email')
        } else (
            setErrorPasswordNotMatch('hide')
        )
    }

    function toLowerCase(e) {
        if (regexpToLowerCase.test(e.target.value) === true) {
            setErrorPasswordLowerCase('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordLowerCase('error-change-email unhide-error-email')
        }
    }

    function toUpperCase(e) {
        if (regexpToUpperCase.test(e.target.value) === true) {
            setErrorPasswordUpperCase('error-change-email unhide-error-paswword')
        } else  {
            setErrorPasswordUpperCase('error-change-email unhide-error-email')
        }
    }

    function toDigitCase(e) {
        if (regexpToDigitCase.test(e.target.value) === true) {
            setErrorPasswordDigitCase('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordDigitCase('error-change-email unhide-error-email')
        }
    }

    function toLengthPassword(e) {
        if (e.target.value.length >= 8) {
            setErrorPasswordLength('error-change-email unhide-error-paswword')
        } else {
            setErrorPasswordLength('error-change-email unhide-error-email')
        }
    }

    return(
        <div className="reset_password">
            <div className="login-container">
            {flag ? 
                <div className="register-form__page">
                    <div className="password_successfuly">
                        <div className="check" />
                        <div className="successfuly_onchange">Пароль успешно изменен</div>
                    </div>                                                
                </div>
                :                
                <div className="register-form__page">
                    <div className="register-form__container">
                        <h2 className="register-form__header">Изменение пароля</h2>
                        <p className="register-form__description">Создайте новый пароль, который будет привязан к вашему аккаунту.</p>
                        <div className="form-reset_line" />
                        <p className="register-form__description">Пароль должен состоять минимум из 8 символов.<br/>Не используйте простые слова и комбинации.</p>
                        <form onSubmit={() => {setTimeout(sending, 4000); setFlag(true)}} className="reset_password_form">
                            <div className="d-flex justify-content-between register-form__container-input">
                                <div className="res_pas_input_form">
                                    <p className="res_pas_input_form__label">Новый пароль</p>
                                    <input type="password"
                                        placeholder="******" 
                                        onChange={e => checkPassword(e)} 
                                        className="reset-form__input"/>
                                    <p className={errorPasswordUpperCase}>* требуются буквы в верхнем регистре</p>
                                    <p className={errorPasswordLowerCase}>* требуются буквы в нижнем регистре</p>
                                    <p className={errorPasswordDigitCase}>* требуются цифры</p>
                                    <p className={errorPasswordLength}>* длина пароля минимум 8 символов</p>
                                </div>
                                <div className="res_pas_input_form">
                                    <p className="res_pas_input_form__label">Подтвердите новый пароль</p>
                                    <input type="password"
                                        placeholder="******" 
                                        onChange={e => checkRepeatPassword(e)} 
                                        className="reset-form__input" />
                                    <p className={errorPasswordNotMatch}>* не совпадают Пароли</p>    
                                </div>                                                              
                            </div>                            
                            <div className="reset_btn">
                                <button onSubmit={() => {setTimeout(sending, 1000); setFlag(true)}} disabled={pushBtn} className="register-form__btn_without_margin">Изменить</button>
                            </div>                            
                        </form>
                    </div>
                </div>
            }                    
            </div>            
            {/* <footer className="footer">
                <FooterPage />                      
            </footer> */}
        </div>
        
    )
}