import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { ReactComponent as Logo } from "./static/repeatTemplate_icon.svg"
import DropDown from '../../UIcomponents/DropDown/DropDown';
import ConverterUTC from '../../ConverterUTC/ConverterUTC';
import { useSelector } from 'react-redux';
import ToolTipComponent from '../../UIcomponents/Tooltips/Tooltip';
import { useOutsideClick } from '../../customHooks/useOutsideClick';

interface DDSelectRepeatTemplateProps {
    setPeriod: Function,
    alertNullPeriod: boolean,
    setAlertNullPeriod: Function,
    alertNullPeriodIcon: boolean
    setAlertNullPeriodIcon: Function
    scroll: Function
}

interface SettingsUserState {
    settings: {
        settings: []
    }
}

export default function DDSelectRepeatTemplate({setPeriod, alertNullPeriod, setAlertNullPeriod, alertNullPeriodIcon, setAlertNullPeriodIcon, scroll}:DDSelectRepeatTemplateProps) {
    const [isActive, setIsActive] = useState<boolean>(false);
    const [periodIsPicked, setPeriodIsPicked] = useState<boolean>(false);
    const [pickedOption, setPickedOption] = useState<number | null>(null);
    const settingsUser = useSelector((state: SettingsUserState) => state.settings.settings)
    const [dateNow, setDateNow] = useState<Date>(new Date())
    const refComponent = useRef(null)
    const [windowScreen, setWindowScreen] = useState(window.screen.width);
    useOutsideClick(refComponent, () => setIsActive(false), isActive)
    const stylesList = {
        width: windowScreen > 410 ? '303px' : '268px',
        backgroundColor: '#F8C440',
        top: windowScreen > 410 ? '160%' : '75%',
        right: windowScreen > 410 ? null : '25%',
        left: windowScreen > 410 && windowScreen < 992 ? null : windowScreen < 410 ? '0%' : '-100%',
        transform: windowScreen > 410 ? null : 'translateX(20%)'
      }
    const stylesListArrow = {
        top: '-8%',
        left: windowScreen > 410 ? '6%' : null,
        right: windowScreen > 410 ? null : '25.5%'
      }
    const stylesContainer = {
        position: windowScreen > 410 ? null : 'inherit'
    }
    const positionList = {
        right: '0%',
        marginRight: '12px',
        transform: 'translate(0%, 0%)'
    }

      useEffect(() => {
        const handleUpdateScreenWidth = () => {
          setWindowScreen(window.screen.width)
        }
    
        window.addEventListener('resize', handleUpdateScreenWidth);
    
        return () => {
          window.removeEventListener('resize', handleUpdateScreenWidth);
        };
      }, [windowScreen])
      
    useEffect(() => {
        if (settingsUser) {
            setDateNow(ConverterUTC({method: 'object',  settingsUser: settingsUser}))
        }
    }, [settingsUser])
    
    const handleSetPeriod = (value: number) => {
        setPickedOption(value)
        setPeriod(value)
        setIsActive(false)
        setPeriodIsPicked(true)
        setAlertNullPeriod(false)
        setAlertNullPeriodIcon(false)
    }
    
    useEffect(() => {
        if (alertNullPeriod) {
            scroll()
        }
        // eslint-disable-next-line 
    }, [alertNullPeriod])

    const arrOptionDays: string[] = ['Каждое воскресенье', 'Каждый понедельник', 'Каждый вторник', 'Каждую среду', 'Каждый четверг', 'Каждую пятницу', 'Каждую субботу']
    const optionArr: string[] = ['Каждый день', arrOptionDays[dateNow.getDay()], 'Ежемесячно', 'По будням']
    return (
        <div className='repeatTemplate'>
            <ToolTipComponent autoOpen={alertNullPeriod} active={false} stylesContainer={stylesContainer} stylesList={stylesList} stylesListArrow={stylesListArrow} tooltipHeader='Выберите период повторения' tooltipDescription='Иначе не получится поставить задачу'>
                <div className={alertNullPeriodIcon ? 'repeatTemplate__btn alert_template' : periodIsPicked ? 'repeatTemplate__btn active_template' : 'repeatTemplate__btn'} title={alertNullPeriod ? '' : 'Повторить задачу'} onClick={(e) => {e.stopPropagation()
                    setIsActive(!isActive)}}>
                    <Logo className={isActive || periodIsPicked ? 'repeatTemplate__btn__icon active__btn' : 'repeatTemplate__btn__icon'}/>
                    <p className='repeatTemplate__btn-descr'>{pickedOption !== null ? optionArr[pickedOption - 1] : 'Повторять'}</p>
                </div>
            </ToolTipComponent>
            <DropDown refLink={refComponent} position={windowScreen > 410 ? {} : positionList} isActive={isActive} updateIsActive={setIsActive}>
                <div className="repeatTemplate__option-container">
                    <div style={{display: 'flex', alignItems: 'center', gap: '12px'}}>
                        <Logo className='repeatTemplate__description-logo'/>
                        <p className='repeatTemplate__description'>Повторять задачу</p>
                    </div>
                    <ul className="repeatTemplate__option-list">
                        {
                            optionArr.map((el, index) => {
                                return (
                                    <li className={pickedOption === index + 1 ? "repeatTemplate__option-el picketOption" : "repeatTemplate__option-el"}
                                        onClick={() => handleSetPeriod(index + 1)} 
                                        key={index}>{el}</li>
                                )
                            })
                        }
                        {/* <li className="repeatTemplate__option-el" onClick={() => handleSetPeriod(1)}>Каждый день</li>
                        <li className="repeatTemplate__option-el" onClick={() => handleSetPeriod(2)}>{arrOptionDays[dateNow.getDay()]}</li>
                        <li className="repeatTemplate__option-el" onClick={() => handleSetPeriod(3)}>Ежемесячно</li>
                        <li className="repeatTemplate__option-el" onClick={() => handleSetPeriod(4)}>По будням</li>
                        <li className="repeatTemplate__option-el" style={{pointerEvents: 'none'}}>Хочу по-другому</li> */}
                    </ul>
                </div>
            </DropDown>
        </div>
    )
}
    