import React, { useState } from "react";
import "./index.css";
import NoDataTemplate from "../NoDataTemplate/noDataTemplate";
import {ReactComponent as CloseInfo} from "../../../../../icons/svg/cross_to_close_info.svg"


const TaskCompleted = ({completedTodosOnRange}) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);

    return (
        <div className="TaskCompleted-container">
            <div className="TaskCompleted-content">
                {
                    completedTodosOnRange.length === 0 ? 
                    <div className= {isOpenInfo ? "info-hidden" : "loader"}/> : completedTodosOnRange[0] === 0 ? <NoDataTemplate isOpenInfo={isOpenInfo}/> :
                    <><h1 className={isOpenInfo ? "info-hidden" : "TaskCompleted-data"}>{completedTodosOnRange}</h1>
                    </>
                }
                    <p className={isOpenInfo ? "info-hidden" : "TaskCompleted-description"}>задач выполнено</p>
                    {
                        window.screen.width <= '420' ? 
                        isOpenInfo ? <CloseInfo className="clicked-info__mobile" onClick={() => setIsOpenInfo(!isOpenInfo)}/> : 
                        <span className="TaskCompleted-content__info" 
                            onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className={isOpenInfo ? "TaskCompleted-content__info moved-info" : "TaskCompleted-content__info"} 
                            onMouseEnter={() => setIsOpenInfo(true)}
                            onMouseLeave={() => setIsOpenInfo(false)}/>
                        
                    }
                        <div className={isOpenInfo ? "TaskCompleted-content__info-container" : 'info-hidden'}>
                            <p className="TaskCompleted-content__info-container__description">Сколько выполнено задач за выбранный период.</p>
                        </div>
            </div>
        </div>
    )
}

export default TaskCompleted;