import React from "react";
// import { Link } from "react-router-dom";
import RegisterUser from "../../Register/RegisterApp";

export default function Register({setIsAuth}) {
    return (
        <div className="login-container">
            <RegisterUser setIsAuth={setIsAuth}></RegisterUser>
            {/* <footer className="footer text-secondary">
                <p className="d-flex">
                    <div className="footer_svg"></div>
                    
                    copyright 2023 
                    <Link to={"/register"} className="footer_link">Политика конфеденциальности</Link>  
                </p>
                              
            </footer> */}
        </div>
    )
}
