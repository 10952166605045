import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { getTimeAllTodos } from '../asyncActions/getTimeAllTodos';
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";
import { addWorkTime } from "../asyncActions/WorkTimeActions/addWorkTime";
import request from "../Requests/requests";
import { getSpentTime } from "../asyncActions/SpentTimeTask/getSpentTime";
import { CircularProgressbar } from 'react-circular-progressbar';
import { ReactComponent as ArrowMobile } from '../../src/icons/svg/dashboard_ic/arrow_mobile-input.svg';
import 'react-circular-progressbar/dist/styles.css';
import './dashboardStyles.css';
import ToolTipComponent from "../UIcomponents/Tooltips/Tooltip";

export default function Dashboard() {
  const dispatch = useDispatch();
  const currentDate = new Date()
  const borderlineDateTop = new Date()
  const borderlineDateBot = new Date()
  const time = useSelector(state => state.time.time);
  const countTask = useSelector(state => state.countTask.countTask);
  const spentTime = useSelector(state => state.spentTime.spentTime);
  const countPercentTask = useSelector(state => state.countPercentTask.countPercentTask);
  const [workTime, setWorkTime] = useState('00:00');
  const [warning, setWarning] = useState(false);
  const [windowScreen, setWindowScreen] = useState(window.screen.width);
  const stylesList = {
    width: windowScreen > 410 ? '303px' : '268px',
    backgroundColor: '#F8C440',
    top: windowScreen > 410 ? '160%' : '210%',
    left: windowScreen > 410 && windowScreen < 992 ? '-135%' : windowScreen < 410 ? '0%' : '-100%',
    transform: windowScreen > 410 ? null : 'translateX(-25%)'
  }
  const stylesListArrow = {
    top: '-8%',
    left: windowScreen > 410 ? '7.5%' : '25.5%'
  }


  useEffect(() => {
    const handleUpdateScreenWidth = () => {
      setWindowScreen(window.screen.width)
    }

    window.addEventListener('resize', handleUpdateScreenWidth);

    return () => {
      window.removeEventListener('resize', handleUpdateScreenWidth);
    };
  }, [windowScreen])

  useEffect(() => {
    dispatch(getTimeAllTodos());
    dispatch(getCountTime());
    getWorkTime();
    dispatch(getSpentTime());
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  async function getWorkTime() {
    await request.get('/Time/WorkTime/', { params: { userId: localStorage.getItem('token') } })
      .then(response => response.data)
      .then(data => setWorkTime(data.time))
  }

  function pushWorkTime() {
    const obj = {
      workTime: workTime
    }
    dispatch(addWorkTime(obj));
  }

  useEffect(() => {
    let date = new Date();
    let momentTime = date.getHours() * 60 + date.getMinutes();
    if (workTime !== undefined) {
      let workHours = Number(workTime.slice(0, 2));
      let workMinutes = Number(workTime.slice(3, 6));
      let endWorkIn = workHours * 60 + workMinutes;
      if (time.length !== 0) {
        let timeDifference = endWorkIn - momentTime;
        let timeToTask = time[0].hours * 60 + time[0].minutes;
        if (timeToTask === 0) {
          setWarning(false); //false
          return;
        }
        if (timeDifference < timeToTask) {
          setWarning(true); // true
        } else if (timeDifference >= timeToTask) {
          setWarning(false); //false
        }
      }
    }
  }, [workTime, time])

  function checkTime() {
    if (borderlineDateTop.setHours(0, 0, 0, 0) < currentDate && currentDate < borderlineDateBot.setHours(Number(workTime.slice(0, 2)), Number(workTime.slice(3, 6)), 0, 0)) {
      let momentTime = currentDate.getHours() * 60 + currentDate.getMinutes();
      let timeDifference = ((Number(workTime.slice(0, 2)) * 60) + Number(workTime.slice(3, 6))) - momentTime;
      if (timeDifference < time[0].hours * 60 + time[0].minutes) {
        return (
          <>
            <p style={{ fontWeight: 700 }} className="notification_warning_title">Время для задач превышено на
              <span className="notification_warning_time">
                {Math.floor(((time[0].hours * 60 + time[0].minutes) - timeDifference) / 60) > 0 ?
                  <span>{Math.floor(((time[0].hours * 60 + time[0].minutes) - timeDifference) / 60)}
                    <span style={{ fontSize: '8px' }}> ч</span>
                  </span>
                  : null
                }
                {((time[0].hours * 60 + time[0].minutes) - timeDifference) % 60 > 0 ?
                  <span>{((time[0].hours * 60 + time[0].minutes) - timeDifference) % 60}
                    <span style={{ fontSize: '8px' }}> мин</span>
                  </span>
                  : null
                }
              </span>
            </p>
            <p className="notification_warning_description">Скорректируйте время или конец рабочего дня, иначе вы не успеете выполнить все задачи.</p>
          </>
        )
      }
    } else {
      return (
        <>
          <p style={{ fontWeight: 700 }} className="notification_warning_title">Время для задач превышено</p>
          <p className="notification_warning_description">Скорректируйте конец рабочего дня, иначе вы не успеете выполнить все задачи.</p>
        </>
      )
    }
  }

  return (
    <div className="dashboard">
      {
        time.length && countTask.length && spentTime.length === 0 ?
          <div className="dashborad__loading-data">
            <div className="loader" />
          </div>
          :
          <div className="dashboard__container">
            <div className="dashboard__time">
              <p className="dashboard__time__description">Время на задачи</p>
              <div className="dashboard__time__container">
                {time.map((time, index) =>
                  <p className="dashboard__status-bar__time-to-tasks" key={index}>
                    {time.hours}
                    <p style={{ marginLeft: '4px', marginRight: '4px' }}>ч</p>
                    {time.minutes}
                    <p style={{ marginLeft: '4px' }}>мин</p>
                  </p>)}
                {warning ?
                  <ToolTipComponent
                    active={true}
                    autoOpen={true}
                    stylesList={stylesList}
                    stylesListArrow={stylesListArrow}
                    tooltipComponent={checkTime()}>
                    <div className="warning" />
                  </ToolTipComponent>
                  :
                  null
                }
              </div>
            </div>
            <div className="dashboard__status-bar">
              <ul className="dashboard__status-bar__list">
                <li className="dashboard__status-bar__item spent_item">
                  <p className="dashboard__status-bar__description">Выполнено</p>
                  {spentTime.map((spentTime, index) =>
                    <p className="dashboard__status-bar__data" key={index}>
                      {spentTime.hours}
                      <p className="dashboard__status-bar__data__letter">ч</p>
                      {spentTime.minutes}
                      <p className="dashboard__status-bar__data__letter">мин</p>
                    </p>)}

                </li>
                <li className="dashboard__status-bar__item task_item">
                  <p className="dashboard__status-bar__description">Задачи</p>
                  {countTask.map((countTask, index) =>
                    <p className="dashboard__status-bar__data dashboard__task"
                      key={index}>
                      {countTask.completedTasks}/{countTask.totalTasks}
                    </p>)}
                </li>
                <li className="dashboard__status-bar__item chart_item">
                  <div className="dashboard__progress-bar">
                    <CircularProgressbar value={countPercentTask}
                      strokeWidth={3}
                      text={`${Math.floor(countPercentTask * 10 / 10)}%`}
                      styles={{
                        path: {
                          stroke: `#546FFF`,
                        },
                        trail: {
                          stroke: '#546FFF',
                          opacity: 0.1,
                        },
                        text: {
                          fill: '#FFFFFF',
                          fontSize: '22px',
                          lineHeight: '150%',
                        },
                      }} />
                  </div>
                </li>
              </ul>
            </div>
            <div className="end_day_item">
              <p className="dashboard__status-bar__description end_day_description">Конец рабочего дня
                <span className="before_p">
                  до
                  <input type="time"
                    className="dashborad__input-work-time"
                    value={workTime}
                    onChange={(e) => setWorkTime(e.target.value)}
                    onBlur={() => pushWorkTime()}>
                  </input>
                  <span className="dashborad__input-work-time__mobile-container">
                    <input type="time"
                      className="dashborad__input-work-time__mobile"
                      value={workTime}
                      onChange={(e) => setWorkTime(e.target.value)}
                      onBlur={() => pushWorkTime()}>
                    </input>
                    <ArrowMobile />
                  </span>
                </span>
              </p>
            </div>
          </div>
      }
    </div>
  )
}