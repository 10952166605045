import React, { useState } from "react";
import "./index.css";
import NoDataTemplate from "../../NoDataTemplate/noDataTemplate";
import {ReactComponent as CloseInfo} from "../../../../../../icons/svg/cross_to_close_info.svg"
import { Link, useLocation } from "react-router-dom";


const BestForecast = ({topForecast, currentRange}) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const location = useLocation()
    return (
        <div className="BestForecast-container">
            <div className="BestForecast-content">
                <p className={isOpenInfo ? "info-hidden" : "BestForecast-header"}>Лучший прогноз</p>
                {
                    topForecast.length === 0 ?
                    <div className={isOpenInfo ? "info-hidden" : "loader"}/> : 
                    topForecast[0].bestForecast === null ? <NoDataTemplate style={{marginTop: '30px'}} isOpenInfo={isOpenInfo}/> :
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <div className={isOpenInfo ? "info-hidden" : "TaskForecast-data"}>
                            {topForecast[0].bestForecast.forecast}
                            <span className="stats-percent-span">%</span>
                        </div>
                        <p className={isOpenInfo ? "info-hidden" : "Forecast-description"}> 
                            <Link to={`/statistics/${topForecast[0].bestForecast.taskId}/modal=${true}`} state={{path: location.pathname, dateRange: currentRange}}>
                                {topForecast[0].bestForecast.taskName.replace(/(<([^>]+)>)/gi, '')}
                            </Link>
                        </p>
                    </div>
                }
                {
                        window.screen.width <= '420' ? 
                        isOpenInfo ? <CloseInfo className="clicked-info__mobile" onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className="TaskCompleted-content__info"
                            onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className={isOpenInfo ? "TaskCompleted-content__info moved-info" : "TaskCompleted-content__info"} 
                            onMouseEnter={() => setIsOpenInfo(true)}
                            onMouseLeave={() => setIsOpenInfo(false)}/>
                        
                    }
                    <div className={isOpenInfo ? "TaskForecast-content__info-container" : "info-hidden"}>
                        <p className="TaskForecast-content__info-container__description">Самый точный прогноз. 
                        <br className="mobile-br-hidden" /><br />
                        По какой задаче лучше всего спрогнозировали время на выполнение. </p>
                    </div>
            </div>
        </div>
    )
}

export default BestForecast;