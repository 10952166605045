import React, { useRef, useState } from "react";
import "./index.css";
import NoDataTemplate from "../../NoDataTemplate/noDataTemplate";
import PieDiagramm from "../../PieDiagramm/PieDiagramm";
import { useOutsideClick } from "../../../../../../customHooks/useOutsideClick";
import { ReactComponent as TaskSelctArrow } from "../../../../../../icons/svg/TaskStats/stats_arrow_colored.svg";
import { ReactComponent as CloseInfo } from "../../../../../../icons/svg/cross_to_close_info.svg"
import CustomInputSelect from "../../../../../../UIcomponents/CustomInputSelect/CustomInputSelect";


const TopTags = (props) => {
    const [isOpenInfo, setIsOpenInfo] = useState(false);
    const [menuActivTypePicker, setMenuActivTypePicker] = useState(false);
    const updateDataDefaultTagsType = (e) => {
        props.onChangeTagType(e.target.value)
    }
    const colorsMarkers = ['rgba(35, 35, 96, 1)', 
                        'rgba(198, 199, 248, 1)', 
                        'rgba(89, 50, 234, 1)' , 
                        'rgba(149, 164, 252, 1)']
    const stylesButton = {
        border: '1px solid #EBEEF2',
        padding: ((window.screen.width >= 768) && (window.screen.width <= 846)) ? '6px' : '8px',
        height:  ((window.screen.width >= 768) && (window.screen.width <= 846)) ? '26px' : '32px',
        backgroundColor: 'rgba(255, 255, 255, 0.10)',
    }
    const stylesTitle = {
        color: '#FFFFFF',
        fontSize: ((window.screen.width >= 768) && (window.screen.width <= 846)) ? '10px' : '12px'
    }
    const stylesLogo = {
        display: 'flex',
        justifyContent: 'center',
        width: '16px',
        height: '16px'
    }
    const refCloseTypePicker = useRef();
    useOutsideClick(refCloseTypePicker, () => setMenuActivTypePicker(false), menuActivTypePicker);

    return (
        <div className="TopTags-container">
            <div className="TopTags-content">
                <div className="TopTags-content__select">
                    <h2 className={isOpenInfo ? "info-hidden" : "TopTags-content__heading"}>ТОП-Метки</h2>
                    <div ref={refCloseTypePicker}
                        onClick={() => setMenuActivTypePicker(!menuActivTypePicker)}>
                            {
                                isOpenInfo ? null :
                                <CustomInputSelect stylesTitle={stylesTitle} stylesLogo={stylesLogo} stylesSelect={stylesButton} title={props.defaultTagsType === 1 ? 'по задачам' : 'по времени'}
                                    logo={<TaskSelctArrow />} 
                                    onClickFunc={() => setMenuActivTypePicker(!menuActivTypePicker)}/>

                            }
                        <ul className={menuActivTypePicker ? "input-select__data-list" : 'info-hidden'}>
                            <li className={props.defaultTagsType === 1 ? "input-select__data-item active-select" : "input-select__data-item"} 
                                value={1} 
                                onClick={(e) => updateDataDefaultTagsType(e)}>
                                    по задачам
                            </li>
                            <li className={props.defaultTagsType === 2 ? "input-select__data-item active-select" : "input-select__data-item"} 
                                value={2} 
                                onClick={(e) => updateDataDefaultTagsType(e)}>
                                    по времени
                            </li>
                        </ul>
                    </div>
                    {
                        window.screen.width <= '420' ? 
                        isOpenInfo ? <CloseInfo className="clicked-info__mobile" onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className="TaskCompleted-content__info" 
                            onClick={() => setIsOpenInfo(!isOpenInfo)}/> :
                        <span className={isOpenInfo ? "TaskCompleted-content__info moved-info" : "TaskCompleted-content__info"} 
                            onMouseEnter={() => setIsOpenInfo(true)}
                            onMouseLeave={() => setIsOpenInfo(false)}/>
                        
                    }
                </div>
                <div className={isOpenInfo ? "info-hidden" : "TopTags-content__list"} 
                    style={isOpenInfo ? {height: '8vh', justifyContent: 'center'} : 
                        props.topTags.length === 0 ? null : 
                            props.topTags[0].tags.length === 0 ? {marginLeft: 'auto', marginRight: 'auto'} : 
                                null}>
                    {props.topTags.length === 0 ? <div style={{marginLeft: 'auto', marginRight: 'auto'}} className={isOpenInfo ? "info-hidden" : "loader"}/> : props.topTags[0].tags.length === 0 ? 
                        <NoDataTemplate isOpenInfo={isOpenInfo}/> : <>
                            {props.topTags.length === 0 ? null : <PieDiagramm data={props.topTags}/>}
                            <ul className="TopTags-list">
                                {
                                            props.topTags[0].tags.map( (tag, index) => 
                                                <li className="TopTags-list__item">
                                                    <span className="TopTags-list__bg" style={{backgroundColor: colorsMarkers[index]}}/>
                                                    <p className="TopTags-list__description">{tag.title}</p>
                                                    <p className="TopTags-list__data">{tag.percent}%</p>
                                                </li>
                                            )
                                }
                            </ul>
                        </>
                    }
                </div>
                
                    <div className={isOpenInfo ? "SpentTime-content__info-container" : "info-hidden"}>
                        <p className="SpentTime-content__info-container__description">Выводится ТОП-4 задачи. По каким меткам выполнено 
                        больше всего задач или затрачено времени.</p>
                    </div>
            </div>
        </div>
    )
}

// {
//     props.topTags.length === 0 ? <div className="loader"/> : 
//         props.topTags[0].tags.length === 0 ? <NoDataTemplate isOpenInfo={isOpenInfo}/> :
//             props.topTags[0].tags.map( (tag, index) => 
//                 <li className="TopTags-list__item">
//                     <span className="TopTags-list__bg" style={{backgroundColor: colorsMarkers[index]}}/>
//                     <p className="TopTags-list__description">{tag.title}</p>
//                     <p className="TopTags-list__data">{tag.percent}%</p>
//                 </li>
//             )
// }

export default TopTags;