// import { Link } from "react-router-dom";
import AuthUser from "../../Auth/AuthApp";

export default function LoginPage({setIsAuth}) {
    
    return (
        <div className="login-container">
            <AuthUser setIsAuth={setIsAuth}></AuthUser>
            {/* <footer className="footer text-secondary">
                <p className="d-flex">
                    <div className="footer_svg"></div>
                    copyright 2023 
                    <Link to={"/login/"} className="footer_link">Политика конфеденциальности</Link>  
                </p>                              
            </footer> */}
                            
        </div>
    )
}
