import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { loadRunTimeResults } from "../../store/RunTimeTaskResults/RunTimeResults";
import TodoList from "../../Todos/TodosList";
import Loader from "../../Loader/Lodaer";
import { getCalendarTodos } from "../../asyncActions/CalendarService/getCalendarTodos";
import './CalendarPage.css'
import { ReactComponent as CalendarIcon } from "./static/calendar.svg"
import { ReactComponent as Right } from "./static/right.svg"
import { ReactComponent as Left } from "./static/left.svg"
import Filter from "../../Filter/Filter";
import TodosAddApp from "../../Todos/TodosAddApp";
import useDebounce from "../../utils/use-debounce";
import { useClickOutside } from "../../customHooks/useClickOutside";
import { ReactComponent as ModalTitleImg } from "../../icons/svg/submenu_ic/calendar-active.svg";
import { ReactComponent as AddTaskPlus } from "../../icons/svg/add-task-plus.svg";
import { ReactComponent as AddTaskPlusMobile } from "../../icons/svg/add-task-plus__mobile.svg";
import { addOnContextState } from "../../store/contextMenuTaskReducer";
import DDSelectDate from "../../DropDownMenu/SelectDateCalendar/DDSelectDate";
import ConverterUTC from "../../ConverterUTC/ConverterUTC";
import { useConnectionHubContext } from "../../Context/connectionHubContext";
import { getCountTime } from "../../asyncActions/StatisticsActions/getCountTime";
import { getTimeAllTodos } from "../../asyncActions/getTimeAllTodos";


const getWeekDay = (day) => {
  switch (day) {
    case 1:
      return 'Пн';
    case 2:
      return 'Вт';
    case 3:
      return 'Ср';
    case 4:
      return 'Чт';
    case 5:
      return 'Пт';
    case 6:
      return 'Сб';
    case 0:
      return 'Вс';
    default:
      break;
  }
}

const TodoListCalendar = ({ setIcon }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const todosCalendar = useSelector(state => state.todosCalendar.calendarTodos);
  const [date, setDate] = useState();
  const [data, setData] = useState();
  const [currdate, setCurrdate] = useState(new Date());
  const [currentWeekDate, setCurrentWeekDate] = useState();
  const [dateArr, setDateArr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [scroll, setScroll] = useState(0);
  const scrollRef = useRef(null);
  const refCalendar = useClickOutside(() => {
    setIsOpenCalendar(false);
  });
  const connectionHub = useConnectionHubContext()
  const settingsUser = useSelector((state) => state.settings.settings)
  useEffect(() => {
    if (settingsUser.length !== 0) {
      setLoading(false);
      setDate(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
      setCurrdate(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
    }
  }, [settingsUser]);

  useEffect(() => {
    connectionHub.updateHub?.on("Receive", (userId) => {
      dispatch(getTimeAllTodos());
      dispatch(getCountTime());
      dispatch(getCalendarTodos(date?.toDateString()))
    });
    return () => {
      connectionHub.updateHub?.off("Receive")
    }

  }, [connectionHub, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data) {
      if (window.innerWidth > 512) {
        setDate(new Date(data.date.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1')));
      } else {
        setDate(new Date(data.date.replace(/(\d*).(\d*).(\d*)/, '$3-$2-$1')));
      }

      let newDateWeek;
      if (window.innerWidth > 512) {
        newDateWeek = getWeek(new Date(data.date.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1')));
      } else {
        newDateWeek = getWeek(new Date(data.date.replace(/(\d*).(\d*).(\d*)/, '$3-$2-$1')));
      }

      let dateWeek = getWeek(date);
      if (window.innerWidth > 1220) {
        dateWeek = scroll / 672 + 1;
      }
      if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
        dateWeek = scroll / 518 + 1;
      }
      if (window.innerWidth > 512 && window.innerWidth <= 1024) {
        dateWeek = scroll / 434 + 1;
      }
      if (window.innerWidth <= 512) {
        dateWeek = scroll / 294 + 1;
      }
      if (newDateWeek > dateWeek) {
        let borderDate = new Date(dateArr[dateArr.length - 1]);
        let i = 0;
        while (i < 7 * (newDateWeek - dateWeek) + 1) {
          dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
          i++;
        }
        if (window.innerWidth > 1220) {
          setScroll(scroll + (672 * (newDateWeek - dateWeek)));
        }
        if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
          setScroll(scroll + (518 * (newDateWeek - dateWeek)));
        }
        if (window.innerWidth > 512 && window.innerWidth <= 1024) {
          setScroll(scroll + (434 * (newDateWeek - dateWeek)));
        }
        if (window.innerWidth <= 512) {
          setScroll(scroll + (294 * (newDateWeek - dateWeek)));
        }
      } else {
        if (window.innerWidth > 1220) {
          setScroll(scroll - (672 * (dateWeek - newDateWeek)));
        }
        if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
          setScroll(scroll - (518 * (dateWeek - newDateWeek)));
        }
        if (window.innerWidth > 512 && window.innerWidth <= 1024) {
          setScroll(scroll - (434 * (dateWeek - newDateWeek)));
        }
        if (window.innerWidth <= 512) {
          setScroll(scroll - (294 * (dateWeek - newDateWeek)));
        }
      }

    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [data]);

  const handleScroll = () => {
    if (scrollRef.current) {
      const scrollX = scrollRef.current.scrollLeft;
      setScroll(scrollX);
    }
  };

  const getWeek = (date) => {
    var newDate = new Date(date.getTime());
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(newDate.getDate() + 4 - (newDate.getDay() || 7));
    var yearStart = new Date(currentWeekDate);
    var weekNumber = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    return weekNumber;
  }


  useEffect(() => {
    if (window.innerWidth > 1220) {
      if (scroll >= scrollRef.current?.scrollWidth - 672) {
        let borderDate = new Date(dateArr[dateArr.length - 1]);
        console.log(borderDate);
        let i = 0;
        while (i < 7) {
          dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
          i++;
        }
        console.log(dateArr);

      }
    }
    if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
      if (scroll >= scrollRef.current?.scrollWidth - 518) {
        let borderDate = new Date(dateArr[dateArr.length - 1]);
        let i = 0;
        while (i < 7) {
          dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
          i++;
        }
      }
    }
    if (window.innerWidth > 512 && window.innerWidth <= 1024) {
      if (scroll >= scrollRef.current?.scrollWidth - 435) {
        let borderDate = new Date(dateArr[dateArr.length - 1]);
        let i = 0;
        while (i < 7) {
          dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
          i++;
        }
      }
    }
    if (window.innerWidth <= 512) {
      if (scroll >= scrollRef.current?.scrollWidth - 300) {
        let borderDate = new Date(dateArr[dateArr.length - 1]);
        let i = 0;
        while (i < 7) {
          dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
          i++;
        }
      }
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [scroll]);

  const updateData = (value) => {
    setIsOpen(value)
  }
  const currentDate = ConverterUTC({ method: "object", settingsUser: settingsUser });
  const currentWeek = () => {
    console.log(currentDate);
    if (currentDate instanceof Date) {
      let arr = [];
      if (currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() - 7);
      } else {
        currentDate.setDate(currentDate.getDate() - currentDate.getDay());
      }
      let i = 0;
      while (i < 14) {
        arr = [...arr, new Date(currentDate.setDate(currentDate.getDate() + 1))];
        i++;
      }
      setCurrentWeekDate(arr[0]);
      return arr;
    }
  }

  useEffect(() => {
    const arr = currentWeek();
    setDateArr(arr);
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [settingsUser])

  const debounseScroll = useDebounce(scroll, 50);

  useEffect(() => {
    scrollRef.current?.scrollTo({
      left: debounseScroll,
      behavior: "smooth"
    });
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [debounseScroll])

  const onChangeDate = (newDate) => {
    setDate(newDate);
  }

  useEffect(() => {
    if (settingsUser.length !== 0) {
      dispatch(getCalendarTodos(date?.toDateString()));
      setIcon(<ModalTitleImg style={{ width: '18px' }} />)
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [date])


  const onClickLeft = () => {
    if (window.innerWidth > 1220) {
      if (scroll >= 662) {
        setScroll(scroll - 672);
      }
    }
    if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
      if (scroll >= 508) {
        setScroll(scroll - 518);
      }
    }

    if (window.innerWidth > 512 && window.innerWidth <= 1024) {
      console.log(scroll)
      if (scroll >= 424) {
        console.log('scroll')
        setScroll(scroll - 434);
      }
    }
  }

  const onClickRight = () => {
    let borderDate = new Date(dateArr[dateArr.length - 1]);
    let i = 0;
    while (i < 7) {
      dateArr.push(new Date(borderDate.setDate(borderDate.getDate() + 1)));
      i++;
    }
    if (window.innerWidth > 1220) {
      setScroll(scroll + 672);
    }

    if (window.innerWidth > 1024 && window.innerWidth <= 1220) {
      setScroll(scroll + 518);
    }

    if (window.innerWidth > 512 && window.innerWidth <= 1024) {
      setScroll(scroll + 434);
    }
  }

  return (
    <div className="todo-list">
      {loading ? <Loader></Loader> :
        <div>
          <div className="calendar_block">
            <div ref={scrollRef} onScroll={() => handleScroll()} className="week_block">
              {dateArr.map((x, i) =>
                <div key={i} className={currdate.getTime() <= (x.getTime() + (settingsUser.currentTimeZone * 60) * 60000) ? "calendar_block_hover" : "calendar_block_not_hover"}
                  onClick={() => currdate.getTime() <= (x.getTime() + (settingsUser.currentTimeZone * 60) * 60000) && onChangeDate(x)}
                >
                  <div className={"calendar_block_btn" + (date.toLocaleDateString() === x.toLocaleDateString() ? " calendar_block_btn_active" : "")}>
                    <div className={"calendar_block_btn_date" +
                      (x.getDay() === 6 || x.getDay() === 0 ? " calendar_block_btn_date_weekend" : "") +
                      (currdate.getTime() > (x.getTime() + (settingsUser.currentTimeZone * 60) * 60000) ? " calendar_block_btn_not_active" : "")}
                    >
                      {x.getDate()}
                    </div>
                    <div className={"calendar_block_btn_day" +
                      (x.getDay() === 6 || x.getDay() === 0 ? " calendar_block_btn_date_weekend" : "") +
                      (currdate.getTime() > (x.getTime() + (settingsUser.currentTimeZone * 60) * 60000) ? " calendar_block_btn_not_active" : "")}
                    >
                      {getWeekDay(x.getDay())}
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div onClick={() => scroll > 0 && onClickLeft()} className={scroll > 0 ? "calendar_block_left" : "calendar_block_left_notactive"}>
              <Left className={scroll > 0 ? "left_img" : "left_img_notactive"} />
            </div>
            <div onClick={() => onClickRight()} className="calendar_block_right">
              <Right className="right_img" />
            </div>
            <div>
              <div onClick={() => setIsOpenCalendar(!isOpenCalendar)} className="calendar_block_calendar">
                <CalendarIcon className="calendar_block_calendar_img" />
              </div>
              <div>
                {isOpenCalendar &&
                  <div ref={refCalendar} className="calendar_calendar">
                    <DDSelectDate
                      calendarPosition={window.screen.width > 576 ? { top: '30px', right: '-40px' } : { top: '30px', right: '0px' }}
                      typeOfCalendar={1}
                      addTaskButtons
                      active={isOpenCalendar}
                      datePayload={date}
                      setActive={setIsOpenCalendar}
                      updateDataDate={setData}
                      disabledItem={'noDate'}
                      currentDateProp={data} />
                  </div>
                }
              </div>
            </div>
          </div>
          <div className="todo-list__container">
            <div>
              <div id='todosMenu' className="task-actions-container to_the_end">
                {/* <GroupChangeMenu /> */}
                <div className="d-flex">
                  <div className={isOpen ? 'addTaskBtn__hide' : 'addTaskBtn'} onClick={() => setIsOpen(!isOpen)}>
                    <AddTaskPlus className="addTaskPlus" />
                    <AddTaskPlusMobile className="addTaskPlus-Mobile" />
                  </div>
                  <Filter />
                </div>
              </div>
            </div>
            {isOpen && (
              <div>
                <TodosAddApp autofocus={true} dateFromCalendarPage={date} datePayload={date} updateData={updateData}></TodosAddApp>
              </div>
            )}
            <ul className="todo-list__list">
              {todosCalendar.length ? (<div>
                {todosCalendar.map((todos, index) => {
                  dispatch(loadRunTimeResults({ isValue: false, taskId: todos.id }))
                  dispatch(addOnContextState({ contextIsOpen: false, contextTaskId: todos.id }))
                  return <TodoList
                    completeBtn
                    timerDisplay
                    fromTodo
                    fromToday
                    todos={todos}
                    dateFromCalendarPage={date?.toDateString()}
                    key={index} />
                })}
              </div>) : loading ? null : (<p className="todo-list__is-null">В Вашем календаре пока что пусто</p>)}
            </ul>
          </div>
        </div>
      }
    </div>
  )
}

export default TodoListCalendar;
