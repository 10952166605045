import { useEffect } from "react";

export const useChangeFromContextMenu = (ref, handler, pushChange, updateTaskList, closeCalendar, calendar, opened, task, taskData) => {
  useEffect(() => {
    if (!opened) return;

    const handleClick = (e) => {
      if (!ref.current || ref.current.contains(e.target)) return;
      if (calendar) {
        closeCalendar()
      }
      if (!(JSON.stringify(task) === JSON.stringify(taskData)) & !calendar) {
        pushChange().then(() => {
          updateTaskList()
        })
      }
      if (!calendar) {
        handler();
      }
    }

    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    }

  }, [ref, handler, opened, pushChange, updateTaskList, closeCalendar, calendar, task, taskData])

}