import './index.css';
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from 'react-redux/es/exports';
import { addTags } from "../../asyncActions/addTag";
import MainTagsApp from "./MainTagsApp";
import { ReactComponent as Close } from "../../icons/svg/menu_ic/close_match.svg"

const DDSelectTags = ({path, active, setActive, updateTagList, tagList, removeTagFromTagList, taskId, modalShow}) => {
    const [tagName, setTagName] = useState();
    const dispatch = useDispatch();
    const [wordsCounter, setWordsCounter] = useState();
    const [matchRegExp, setMatchRegExp] = useState('');
    const [isActiveCounter, setIsActiveCounter] = useState(false);
    const maxLenghtOfTitle = 60;
    const [windowScreen, setWindowScreen] = useState(window.screen.width);
    const [widthInputTag, setWidthInputTag] = useState('80%');
    const refInputTag = useRef();
    function checkLenghtOfWords(value) {
        if (value.length === 35) {
            setTagName(value)
            return setWordsCounter(value.length) & setIsActiveCounter(true);
        } else if ((value.length >= 35) & (value.length <= maxLenghtOfTitle)) {
            setTagName(value)
            return setWordsCounter(value.length);
        } else if (value.length < 35) {
            setTagName(value)
            return setIsActiveCounter(false);
        }
    }

    

    function handleAddTags(e) {
        e.preventDefault();
        setTagName(e.target.value)
        const newobj = {
            tagName: tagName
        }
        dispatch(addTags(newobj));
    }

    useEffect(() => {
        const handleUpdateScreenWidth = () => {
          setWindowScreen(window.screen.width)
        }
    
        window.addEventListener('resize', handleUpdateScreenWidth);
    
        return () => {
          window.removeEventListener('resize', handleUpdateScreenWidth);
        };
      }, [windowScreen])
    

    useEffect(() => {
        if (!active) {
            setMatchRegExp('')
        }
    }, [setMatchRegExp, active])
    
    function resizeTagInput(e) {
        if (e.target.scrollWidth < 230) {
            return
        } else if (e.target.value === '') {
            setWidthInputTag('200px');
        } else {
            setWidthInputTag(e.target.scrollWidth + 'px');
        }
        // if(e.target.value === '') {
        //     setWidthInputTag('100%');
        // }        
    }
    function stopEnter(e) {
        if (e.keyCode === 13) {
            dispatch(addTags({tagName: matchRegExp}))
            e.preventDefault()
        }
    }

    const closeFinder = () => {
        setMatchRegExp('')
        setWidthInputTag('200px')
        setWordsCounter(0)
        setIsActiveCounter(false)
    }


    useEffect(() => {
        if (active && windowScreen > 576) {
            refInputTag.current.focus()
        }
        
    }, [refInputTag, active, windowScreen])

    return(
        <div className={active ? 'menu active' : 'menu'} >
            <div className={path === 'addTask' ? 'tags__container' : 'tags__container__edit-task'} 
                onClick={e => e.stopPropagation()}>
                <form onSubmit={handleAddTags} onKeyDown={(e) => stopEnter(e)}>
                    <div className='tags__get-tag-container'>
                        <div className='tags_get-tag__input'>
                            <input type="text"
                                ref={refInputTag} 
                                maxLength='60'
                                placeholder="Введите название метки" 
                                className='tags__get-tag'
                                value={matchRegExp} 
                                style={{width: widthInputTag}}
                                onInput={(e) => setMatchRegExp(e.target.value) & resizeTagInput(e)} 
                                onChange={(e) => checkLenghtOfWords(e.target.value)}/>
                            <div style={matchRegExp ? {display: 'block'} : {display: 'none'}}>
                                <Close style={{marginLeft: '20px', cursor: 'pointer'}} 
                                    onClick={() => closeFinder()}/>
                            </div>
                        </div>
                        <p className={isActiveCounter ? 'ddselect-alert alertCounterLengthOfTitle ' : 'hide'}>
                            Лимит {wordsCounter}/60 
                        </p>
                    </div>
                </form>
                <div className="tags__line"></div>
                <div className='tags__data-container'>
                    <MainTagsApp taskId={taskId}
                            closeFinder = {closeFinder} 
                            matchRegExp={matchRegExp} 
                            modalShow={modalShow} 
                            updateTagList={updateTagList}
                            tagList={tagList}
                            removeTagFromTagList={removeTagFromTagList}/>
                </div>
            </div>
        </div>
    )
}

export default DDSelectTags;