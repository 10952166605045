import request from "../Requests/requests";
import { removeTodoToday } from "../store/todosReducer";
import { removeTodoIncoming } from "../store/incomingTodosReducer";
import { removeTodoCompleted } from "../store/completedTodosReducer";
import { getSpentTime } from "./SpentTimeTask/getSpentTime";
import { removeTimer } from "../store/RunTimeTaskResults/RunTimeResults";

export const removeTodo = (payload) => {
  return async dispatch => {
    // eslint-disable-next-line
    await request.put('/Task/Delete?Id=' + `${payload.id ? payload.id : payload}`);
    dispatch(removeTimer(payload.id ? payload.id : payload))
    dispatch(removeTodoToday(payload.id ? payload.id : payload));
    dispatch(removeTodoIncoming(payload.id ? payload.id : payload));
    dispatch(removeTodoCompleted(payload.id ? payload.id : payload));
    setTimeout(() => {
      dispatch(getSpentTime());
    }, 500);
  }
}