import request from "../../Requests/requests"
import { Dispatch } from "redux";
import { getRepeatTemplates } from "./getRepeatTemplates";

interface PayloadObject {
    title: string; 
    description: string; 
    date: string; 
    time: string; 
    priority: number; 
    hours: number; 
    minutes: number; 
    tagsId: []; 
    dateFrom?: any; 
    repitPeriod: number 
}

export function addRepeatTemplate(payload: PayloadObject) {
    return async (dispatch: Dispatch) => {
        await request.post('/RepitTemplate/', {
            userId: localStorage.getItem('token'),
            title: payload.title,
            description: payload.description,
            date: payload.date,
            time: payload.time ? payload.time : null,
            priority: payload.priority,
            hours: payload.hours,
            minutes: payload.minutes,
            tagsId: payload.tagsId ? payload.tagsId : null,
            repitPeriod: payload.repitPeriod 
        })
        .then(() => dispatch<any>(getRepeatTemplates()))
    }
}