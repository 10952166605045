import React, { useEffect, useState } from "react";
import Loader from "../Loader/Lodaer";
import TodoList from "./TodosList";
import TodosAddApp from "./TodosAddApp";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import Filter from "../Filter/Filter";
import { ReactComponent as AddTaskPlus } from "../icons/svg/add-task-plus.svg";
import { ReactComponent as AddTaskPlusMobile } from "../icons/svg/add-task-plus__mobile.svg";
import { ReactComponent as ModalTitleImg} from "../DropDownMenu/SelectRepeatTemplate/static/repeatTemplate_icon.svg"
import { addOnContextState } from "../store/contextMenuTaskReducer";
import { getRepeatTemplates } from "../asyncActions/RepeatTemplatesService/getRepeatTemplates";
import { useConnectionHubContext } from "../Context/connectionHubContext";
import ConverterUTC from "../ConverterUTC/ConverterUTC";

interface TodosRepeatProps {
    setIcon: Function, 
}

interface TemplatesState {
    templatesState: {
      repeatTemplate: []
    }
}

interface SettingsUserState {
  settings: {
      settings: []
  }
}

export default function TodosRepeatTemplate({setIcon}:TodosRepeatProps) {
  const dispatch = useDispatch();
  const templates = useSelector((state: TemplatesState) => state.templatesState.repeatTemplate)
  const settingsUser = useSelector((state: SettingsUserState) => state.settings.settings)
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [date, setDate] = useState(new Date());
  const connection = useConnectionHubContext();

  const updateData = (value: boolean) => {
    setIsOpen(value)
  }

  useEffect(() => {
    if (settingsUser.length !== 0) {
      setDate(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
    }
  }, [settingsUser]);

  useEffect(() => {
    connection.updateHub?.on("Receive", (userId: string) => {
      if (userId === localStorage.getItem('token')) {
        dispatch<any>(getRepeatTemplates())
      }
    });
    return () => {
      connection.updateHub?.off("Receive")
    }

  }, [connection, dispatch])

  const updateTaskList = () => {
    connection.updateHub.invoke("Send", localStorage.getItem('token'))
      .catch(() => console.error());
  }  

  useEffect(() => {
    dispatch<any>(getRepeatTemplates()).then(() => setLoading(false))
    setIcon(<ModalTitleImg style={{ width: '14px', fill: '#5932EA' }} />)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", checkFastCombinations);
    return () => {
      document.removeEventListener("keydown", checkFastCombinations);
    };
  });

  function checkFastCombinations(e: KeyboardEvent) {
    if (e.altKey && e.keyCode === 77) { //shift + m - открыть/закрыть меню добавления задачи
      setIsOpen(!isOpen)
    }
    if (e.keyCode === 27) {
      setIsOpen(false)
    }
  }
  
  return (
    <div className="todo-list">
      <div className="todo-list__container">
        <div id='todosMenu' className="task-actions-container to_the_end">
          <div className="d-flex">
            <div className={isOpen ? 'addTaskBtn__hide' : 'addTaskBtn'} onClick={() => setIsOpen(!isOpen)}>
              <AddTaskPlus className="addTaskPlus" />
              <AddTaskPlusMobile className="addTaskPlus-Mobile" />
            </div>
            <Filter />
          </div>
        </div>
        {isOpen && (
          <div>
            <TodosAddApp repeatTemplate={true} datePayload={date} updateTaskList={updateTaskList} autofocus={true} updateData={updateData}></TodosAddApp>
          </div>
        )}

        {
          loading ? <Loader /> :
            <ul className="todo-list__list">
              {templates.length ? (<div>
                {templates.map((templates: object, index: number) => {
                  dispatch(addOnContextState({ contextIsOpen: false, contextTaskId: templates.id }))
                  return (
                    <TodoList
                      updateTaskList={updateTaskList}
                      completeBtn
                      timerDisplay
                      fromToday
                      todos={templates}
                      key={index} />
                  )
                })}
              </div>) : loading ? null : (<p className="todo-list__is-null">Ваш список повторяющихся задач пуст</p>)}
            </ul>
        }
      </div>
    </div>
  )
}
