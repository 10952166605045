import TaskModel from "../../../../../../entities/TaskTableModel/TaskModel";
import TaskTableModel from "../../../../../../entities/TaskTableModel/TaskTableModel";

export enum TaskTabelActionTypes {
  TASK_TABLE_LOADING = 'TASK_TABLE_LOADING',
  TASK_TABLE_SUCCESS = 'TASK_TABLE_SUCCESS',
  TASK_TABLE_ERROR = 'TASK_TABLE_ERROR',
  TASK_TABLE_LOAD = 'TASK_TABLE_LOAD',
}

interface TaskTabelLoadingAction {
  type: TaskTabelActionTypes.TASK_TABLE_LOADING;
}

interface TaskTabelSuccessAction {
  type: TaskTabelActionTypes.TASK_TABLE_SUCCESS;
  payload: TaskTableModel;
}

interface TaskTabelErrorAction {
  type: TaskTabelActionTypes.TASK_TABLE_ERROR;
  payload: string;
}

interface TaskTabelLoadAction {
  type: TaskTabelActionTypes.TASK_TABLE_LOAD;
  payload: TaskTableModel;
}

export type TaskTableAction = TaskTabelLoadingAction | TaskTabelSuccessAction | TaskTabelErrorAction | TaskTabelLoadAction;

export interface TaskTableState {
  isLoading: boolean,
  data: TaskTableModel,
  error: string | null
}

const initialState: TaskTableState = {
  isLoading: false,
  data: {
    tasks: [{
      id: 0,
      name: '',
      tags: [''],
      date: '',
      priority: 0,
      factTime: 0,
      planTime: 0,
      forecastAccuracy: 0
    }] as TaskModel[],
    pageIndex: 0,
    totalCount: 0,
    totalPages: 0,
    hasNextPage: false
  },
  error: null,
};


const taskTabelReducer = (state = initialState, action: TaskTableAction): TaskTableState => {
  switch (action.type) {
    case TaskTabelActionTypes.TASK_TABLE_LOADING:
      return { ...state, isLoading: true, error: null };
    case TaskTabelActionTypes.TASK_TABLE_SUCCESS:
      return { ...state, isLoading: false, data: action.payload, error: null };
    case TaskTabelActionTypes.TASK_TABLE_ERROR:
      return { ...state, isLoading: false, error: action.payload };
    case TaskTabelActionTypes.TASK_TABLE_LOAD:
      return { 
        ...state, 
        isLoading: false, 
        data: {
          ...state.data, 
          tasks: [...state.data.tasks, ...action.payload.tasks], 
          hasNextPage: action.payload.hasNextPage}, 
        error: null 
      };
    default:
      return state;
  }
};

export default taskTabelReducer;