import { Dispatch } from "redux"
import request from "../../Requests/requests"
import { loadTemplates } from "../../store/repeatTemplateReducer"


export function getRepeatTemplates() {
    return async (dispatch: Dispatch)=> {
        await request.get('/RepitTemplate/List', {params: {
            UserId: localStorage.getItem('token'),
            TypeSort: 5,
            IsAscending: true,
        }})
        .then(response => response.data)
        .then(json => dispatch(loadTemplates(json)))
    }
}