import { useState } from "react";
import { forgotPassword } from "../../asyncActions/forgotPassword";
// import FooterPage from "../Footer/footer";


export default function ForgotPassword() {
    const [email, setEmail] = useState();
    const [flag, setFlag] = useState(false);
    const [pushBtn, setPushBtn] = useState(true);

    let emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ // eslint-disable-line

    function checkEmail(e) {
        setEmail(e.target.value)
        if(emailReg.test(e.target.value) === true) {
            setPushBtn(false)
        } else {
            setPushBtn(true)
        }
    }

    return (
        <div className="login-container">
            <div className="forgote-pas">

                {flag ?
                    <div className="register-form__page">
                        <span className="forgot-password-sented-mail-img"/>
                        <h2 className="forgot-password-sented-mail">Отправили на почту ссылку для восстановления пароля</h2>
                    </div>
                    :
                    <div className="register-form__page">
                        <div className="register-form__container">
                            <h2 className="register-form__header">Восстановление пароля</h2>
                            <p className="register-form__description">Укажите свой email, под которым вы зарегистрированы и на него будет отправлено письмо о восстановлении пароля.</p>
                            <form onSubmit={() => { forgotPassword(email); setFlag(true) }} className="register-form__form">
                                <input
                                    placeholder="example@ex.com"
                                    onChange={(e) => checkEmail(e)}
                                    className="register-form__input"
                                />
                                <button onClick={() => { forgotPassword(email); setFlag(true) }} disabled={pushBtn} className="register-form__btn">Восстановить</button>
                            </form>
                        </div>
                    </div>
                }
            </div>
            {/* <footer className="footer text-secondary">
                <FooterPage/>
            </footer> */}
        </div>
    )
}


