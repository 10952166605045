import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import request from "../Requests/requests";
import { useNavigate } from "react-router-dom";
import './RegisterApp.css';
import { sendDataLayer } from "../utils/sendDataLayer";
import Cookies from 'universal-cookie';
import Validation from "../UIcomponents/Validation/Validation";


export default function RegisterUser({setIsAuth}) {
  const [firstname, setFirstname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const navigate = useNavigate();
  const [isDisabled, setIsDisabled] = useState(false);
  const [checkedPrivacy, setCheckedPrivacy] = useState(true);
  const [loading, setLoading] = useState(false);
  const focusName = document.getElementById('firstName')
  const focusEmail = document.getElementById('email')
  const focusPassword = document.getElementById('password')
  const focusConfirmPassword = document.getElementById('passwordConfirm')
  const [errorPasswordNotMatch, setErrorPasswordNotMatch ] = useState(true);
  const [errorPasswordUpperCase, setErrorPasswordUpperCase] = useState(true);
  const [errorPasswordLowerCase, setErrorPasswordLowerCase] = useState(true);
  const [errorPasswordDigitCase, setErrorPasswordDigitCase] = useState(true);
  const [errorPasswordLength, setErrorPasswordLength] = useState(true);
  // const [isUpperCase, setIsUpperCase] = useState(false);
  // const [isLowerCase, setIsLowerCase] = useState(false);
  // const [isDigitCase, setIsDigitCase] = useState(false);
  // const [isLength, setIsLength] = useState(false);
  const cookies = new Cookies();
  let arrErrors = new Array([]);
  const [dataErrors, setDataErrors] = useState([]);
  let regexpToLowerCase = /[а-яa-z]/gu // eslint-disable-line
  let regexpToUpperCase = /[А-ЯA-Z]/gu // eslint-disable-line
  let regexpToDigitCase = /[\p{Nd}]/gu // eslint-disable-line
  let emailReg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/ // eslint-disable-line
  /* eslint-disable-next-line */

  useEffect(() => {
    if (localStorage.getItem('token')) {
      navigate('/', { replace: true })
    }
  })

  function handleRegister() {
    // setIsUpperCase(false)
    // setIsLowerCase(false)
    // setIsDigitCase(false)
    // setIsLength(false)
    setDataErrors([])
    request.post('/Account/Register', {
      firstName: firstname,
      email: email,
      password: password,
      passwordConfirm: passwordConfirm,
      timeZone: -new Date().getTimezoneOffset() / 60,
    })
    .then(res => {
      setLoading(false)
      if (res.status === 200) {
        setIsAuth(true)
        sendDataLayer('registration', 'registration')
        localStorage.setItem('token', res.data.id);
        localStorage.setItem('authToken', res.data.token);
        localStorage.setItem('firstName', res.data.firsName);
        localStorage.setItem('lastName', res.data.lastName === null ? '' : res.data.lastName);
        localStorage.setItem('interceptorTry', 'false');
        cookies.set('refreshToken', res.data.refreshToken, {maxAge: 24*60*60*7})
        navigate('/register_success', { replace: true });
      }
    })
    .catch((error) => {
      setLoading(false)
      if(error.response.data.errors.Email != null){
        let arrGetError = Object.entries(error.response.data.errors)
        arrGetError.forEach(([key, value]) => {
          value.map(err => arrErrors.push({key, err}))
          setDataErrors(arrErrors)
        })
      }
    })
  }

  useEffect(() => {
    if ((firstname !== '') && (email !== '') && (password !== '') && (passwordConfirm !== '') && (errorPasswordNotMatch) && (checkedPrivacy === true)) {
        setIsDisabled(false)
    } else {
      setIsDisabled(true)
    }
  }, [firstname, email, password, passwordConfirm, errorPasswordNotMatch, checkedPrivacy])
  
  function focusingPassword(e) {
    if ((e.keyCode === 13) && (e.target.id === 'firstName')) {
      e.preventDefault();
      focusEmail.focus();
    } else if ((e.keyCode === 13) && (e.target.id === 'email')) {
      e.preventDefault();
      focusPassword.focus();
    } else if ((e.keyCode === 13) && (e.target.id === 'password')) {
      e.preventDefault();
      focusConfirmPassword.focus();
    } else if ((e.keyCode === 13) && (e.target.id === 'passwordConfirm') && (isDisabled)) {
      e.preventDefault();
      focusName.focus();
    }
  }

  useEffect(() => {
    if ((passwordConfirm !== password) && (passwordConfirm !== '')) {
      setErrorPasswordNotMatch(false)
    } else {
      setErrorPasswordNotMatch(true)
    }
  }, [passwordConfirm, password])

  useEffect(() => {
    /* eslint-disable-next-line */
    if (!errorPasswordDigitCase || !errorPasswordLength || !errorPasswordLowerCase || !errorPasswordUpperCase) {
      /* eslint-disable-next-line */
      checkPassword(password)
    }
    /* eslint-disable-next-line */
  }, [password])

  function checkRepeatPassword(e) {
    setPasswordConfirm(e.target.value)
    if ((e.target.value !== password) && (e.target.value !== '')) {
        setErrorPasswordNotMatch(false)
    } else (
        setErrorPasswordNotMatch(true)
    )
  }

  function checkPassword(value) {
    toLowerCase(value)
    toUpperCase(value)
    toDigitCase(value) 
    toLengthPassword(value)
  }

  function toLowerCase(value) {
    if (regexpToLowerCase.test(value) === true) {
        setErrorPasswordLowerCase(true)
        // setIsLowerCase(true);
    } else {
        setErrorPasswordLowerCase(false)
        // setIsLowerCase(false)
    }
}

function toUpperCase(value) {
    if (regexpToUpperCase.test(value) === true) {
        setErrorPasswordUpperCase(true)
        // setIsUpperCase(true)
    } else  {
        setErrorPasswordUpperCase(false)
        // setIsUpperCase(false)
        focusPassword.focus()
    }
}

function toDigitCase(value) {
    if (regexpToDigitCase.test(value) === true) {
        setErrorPasswordDigitCase(true)
        // setIsDigitCase(true)
    } else {
        setErrorPasswordDigitCase(false)
        // setIsDigitCase(false)
    }
}

function toLengthPassword(value) {
    if (value.length >= 6) {
        setErrorPasswordLength(true)
        // setIsLength(true)
    } else {
        setErrorPasswordLength(false)
        // setIsLength(false)
    }
}

  function handleSubmit(e) {
    e.preventDefault();
    if (emailReg.test(email) === true) {
      setLoading(true)
      checkPassword(password)
      setDataErrors([])
      handleRegister()
    } else {
      arrErrors.push({key: 'Email', err: 'некорректный email'});
      setDataErrors(arrErrors)
      focusEmail.focus();
    }
  }

  return (
    <div className="register-form__page">
      <div className="register-form__container">
        <h2 className="register-form__heading">Регистрация аккаунта</h2>
        <form onSubmit={handleSubmit} className='register-form__form' autoComplete="off">
          <ul className="register-from__list">
            <li className="register-form__list__item">
              <input type='text' 
                    id='firstName' 
                    name='firstName' 
                    value={firstname}
                    autoComplete="off"
                    onKeyDown={(e) => focusingPassword(e)} 
                    onChange={e => setFirstname(e.target.value)} 
                    placeholder='Имя' 
                    className="register-form__input"/>
            </li>
            <li className="register-form__list__item">
              <input type='text' 
                    id='email' 
                    name='email' 
                    autoComplete="off" 
                    value={email}
                    onKeyDown={(e) => focusingPassword(e)}  
                    onChange={e => setEmail(e.target.value)} 
                    placeholder='Email' 
                    className="register-form__input" 
                    style={dataErrors.key === 'Email' ? {border: '1px solid red'} : null}/>
              <Validation data={dataErrors} keyItem="Email"/>
            </li>
            <li className="register-form__list__item">
              <input type='password' 
                    id='password' 
                    name='password' 
                    autoComplete="off" 
                    value={password}
                    onKeyDown={(e) => focusingPassword(e)}
                    onChange={e => setPassword(e.target.value)}   
                    placeholder='Пароль' 
                    className="register-form__input"
                    style={!errorPasswordUpperCase || !errorPasswordLowerCase || !errorPasswordDigitCase || !errorPasswordLength !== false ? {border: '1px solid red'} : null}/>
                <p className={errorPasswordUpperCase ? 'hide' : 'error-change-email'}>* требуются буквы в верхнем регистре</p>
                <p className={errorPasswordLowerCase ? 'hide' : 'error-change-email'}>* требуются буквы в нижнем регистре</p>
                <p className={errorPasswordDigitCase ? 'hide' : 'error-change-email'}>* требуются цифры</p>
                <p className={errorPasswordLength ? 'hide' : 'error-change-email'}>* длина пароля минимум 6 символов</p>
            </li>
            <li className="register-form__list__item">
              <input type='password' 
                    id='passwordConfirm' 
                    name='passwordConfirm' 
                    autoComplete="off" 
                    value={passwordConfirm}
                    onKeyDown={(e) => focusingPassword(e)}  
                    onChange={e => checkRepeatPassword(e)} 
                    placeholder='Подтверждение пароля' 
                    className="register-form__input" 
                    style={errorPasswordNotMatch !== true ? {border: '1px solid red'} : null}/>
              <p className={errorPasswordNotMatch ? 'hide' : 'error-change-email'}>* не совпадают Пароли</p>
            </li>
          </ul>
          <button onClick={() => handleSubmit} disabled={isDisabled}
                className="register-form__btn">
                    {loading ? 
                        <div className="centered-btn-spinner">
                            <div class="btn-lds-ring">
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        </div> : 'Зарегистрироваться'}
          </button>
          <div className="privacy-check checkList">
            <label className="label_to_checkBox privacy-label" style={{alignItems: 'center'}}>
              <input type="checkbox" className="checkList_checkbox" checked={checkedPrivacy} onClick={() => setCheckedPrivacy(!checkedPrivacy)}/>
              <span></span>
              Я принимаю 
              <a href="https://timetask.ru/privacy" rel="noreferrer" className="privacy-link" target="_blank" style={{marginLeft: '3px'}}>Политику конфиденциальности</a> 
            </label>
          </div>
          <p className="login-description">
            Уже есть аккаунт? <Link to="/login/">Вход</Link>
          </p>
        </form>
      </div>
    </div>

  )
}
