import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import getMonthData from "./Calendar.jsx";
import './index.css';
import { currentDateRangeContext } from "../Context/currentDateRangeContext";
import { currentDateContext } from "../Context/currentDateContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import ConverterUTC from "../ConverterUTC/ConverterUTC.ts";
import useOutside from "../customHooks/useOutside.ts";

export default function Calendar({isActiveToday, isActiveTomorrow, isActiveNoData, setIsActiveNoData, setIsActiveToday, setIsActiveTomorrow, datePayload, ...props}) {
    const location = useLocation();
    const currentRangeContext = useContext(currentDateRangeContext);
    const currentContext = useContext(currentDateContext);
    const settingsUser = useSelector(state => state.settings.settings)
    const borderDateToPastDays = new Date()
    const [date, setDate] = useState(new Date())
     /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const [currentDate, setCurrentDate] = useState();
    const borderLineDateToPrev = {dateOfBirth: new Date('1924-01-01T00:00:00'), dateOfStatistics: new Date('2023-01-01T00:00:00') }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    const datePrev = new Date(date?.getFullYear(), date?.getMonth() - 1);
    const dateNext = new Date(date?.getFullYear(), date?.getMonth() + 1);
    const years = [];
    const birthDayYears = [];
    const typeOfCalendar = props.typeOfCalendar; // typeOfCalendar = 1 Добавление задачи; 
                                                // typeOfCalendar = 2 Изменение даты рождения; 
                                                // typeOfCalendar = 3 Получение статистики; 
                                                // typeOfCalendar = '' стандартный календарь
    const [rangeData, setRangeData] = useState(typeOfCalendar === 3 ? 
        [currentRangeContext.start, currentRangeContext.end] : [])
    const monthNames = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const weekDayNames = ['Пн','Вт','Ср','Чт','Пт','Сб','Вс'];    
    const monthDate =  getMonthData(date?.getFullYear(), date?.getMonth())
    const [selectedDay, setSelectedDay] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedMonth, setSelectedMonth] = useState(date?.getMonth());
    const [selectedYear, setSelectedYear] = useState(date?.getFullYear());
    const [isOpenSelectMonth, setIsOpenSelectMonth] = useState(false);
    const [isOpenSelectYear, setIsOpenSelectYear] = useState(false);
    const [disabledBtnToMovePrev, setDisabledBtnToMovePrev] = useState(typeOfCalendar === 3 ? false : true);
    const [disabledBtnToMoveNext, setDisabledBtnToMoveNext] = useState(false);
    //Хуки для закрытия окон выбора месяца\года
    const refCloseIsOpenSelectMonth = useOutside(() => setIsOpenSelectMonth(false));
    const refCloseIsOpenSelectYear = useOutside(() => setIsOpenSelectYear(false));
    
    for (let i = 1924; i <= currentDate?.getFullYear() - 10; i++) {
        birthDayYears.push(i);
    }
    for (let i = currentDate?.getFullYear(); i <= currentDate?.getFullYear() + 5; i++) {
        years.push(i);
    }
    
    useEffect(() => {
        setDate(datePayload ?? ConverterUTC({method: 'object', settingsUser: settingsUser}))
        setCurrentDate(datePayload ?? ConverterUTC({method: 'object', settingsUser: settingsUser}))
        // setborderDateToPastDays(ConverterUTC({method: 'object', settingsUser: settingsUser}))
    }, [settingsUser, datePayload])
    
    useEffect(() => {
        if ((isActiveToday) || (isActiveTomorrow)) {
            setSelectedDay(null)
        }
    }, [isActiveToday, isActiveTomorrow])

    useEffect(() => {
        if ((typeOfCalendar === 3) && (currentRangeContext.start !== rangeData[0])) {
            setRangeData([currentRangeContext.start, currentRangeContext.end]);
        }
        // eslint-disable-next-line
    }, [currentRangeContext, typeOfCalendar])
    useEffect(() => {
        if ((rangeData.length === 2) ) {           
            props.updateSelectedDataRange({rangeData})
            return
        } else if (rangeData.length > 2) {
            setRangeData([...rangeData.slice(0, 0), ...rangeData.slice(0 + 1)])
        }
        // eslint-disable-next-line
    }, [rangeData])

    // Переключение на прошлый месяц
    const handlePrevMonthButtonClick = () => {
        console.log(datePrev, currentDate);
        switch (typeOfCalendar) {
            case 1:
                if (datePrev < currentDate) {
                    setDate(currentDate);
                    setDisabledBtnToMovePrev(true);
                } else {
                    setDate(datePrev);
                    setDisabledBtnToMoveNext(false);
                    setDisabledBtnToMovePrev(false);
                }
                break;
            case 2:
                if(borderLineDateToPrev.dateOfBirth.toLocaleDateString() === datePrev.toLocaleDateString()) {
                    setDate(datePrev);
                    setDisabledBtnToMovePrev(true);
                } else {
                    setDate(datePrev);
                    setDisabledBtnToMovePrev(false)
                }
                break;
            case 3:
                if(borderLineDateToPrev.dateOfStatistics.toLocaleDateString() === datePrev.toLocaleDateString()) {
                    setDate(datePrev);
                    setDisabledBtnToMovePrev(true);
                } else {
                    setDate(datePrev);
                    setDisabledBtnToMovePrev(false)
                }
                break;
            default:
                if (datePrev < currentDate) {
                    setDate(currentDate);
                    setDisabledBtnToMovePrev(true);
                } else {
                    setDate(datePrev);
                    setDisabledBtnToMoveNext(false);
                    setDisabledBtnToMovePrev(false);
                }
                break;
        }
    }
    
    // Переключение на следующий месяц
    const handleNextMonthButtonClick = () => {
        switch (typeOfCalendar) {
            case 1:
                if ((dateNext.getFullYear() > currentDate.getFullYear() + 5)) {
                    setDate(currentDate);
                    setDisabledBtnToMovePrev(true);
                    // setDisabledBtnToMoveNext(true);
                } else {
                    setDate(dateNext);
                    setDisabledBtnToMoveNext(false);
                    setDisabledBtnToMovePrev(false);
                }
                break;
            case 2:
                if (dateNext.getFullYear() > currentDate.getFullYear() - 10) {
                    setDate(borderLineDateToPrev.dateOfBirth)
                } else {
                    setDate(dateNext);
                    setDisabledBtnToMoveNext(false);
                    setDisabledBtnToMovePrev(false);
                }
                break;
            case 3:
                setDate(dateNext);
                setDisabledBtnToMoveNext(false);
                setDisabledBtnToMovePrev(false);
                break;
            default:
                if ((dateNext.getFullYear() > currentDate.getFullYear() + 5)) {
                    setDisabledBtnToMoveNext(true);
                } else {
                    setDate(dateNext);
                    setDisabledBtnToMoveNext(false);
                    setDisabledBtnToMovePrev(false);
                }
                break;
        }
    }
    
    // Функция выбора даты по месяцу и году из списка
    const handleSelectChange = (keyMonth, keyYear) => {
        let year = keyYear;
        let month = keyMonth;
        console.log(keyMonth, keyYear);
        setSelectedMonth(keyMonth);
        setSelectedYear(keyYear);
        const date = new Date(year, month);
        console.log(new Date(year, month));
        setDate(date);
    }

    // Выделение чисел, входящих в диапазон выбранных дат
    const inRange = (value) => {
        if (((value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') >= rangeData[0]) && 
            (value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') <= rangeData[1])) || 
            ((value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') >= rangeData[1]) && 
            (value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') <= rangeData[0]))) {
            return true;
        } else {
            return false;
        }
    }

    // Выделение начала и конца диапозона выбранных дат
    const onIncludes = (value) => {
            if ((rangeData[0] && value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') === rangeData[0]) || 
            (rangeData[1] && value.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') === rangeData[1])) { 
                return true
            }
        }

    // Выбор даты по клику на день
    const handleDayClick = (date) => {
        setSelectedDate(date.getDate());
        switch (typeOfCalendar) {
            case 1:
                props.setDateToContext(date.toLocaleDateString())
                props.updateDataDate({
                    date: date.toLocaleDateString()
                })
                // props.setActive(false);
                break;
            case 2:
                props.updateDataDate({
                    date: date.toLocaleDateString()
                })
                props.setActive(true);
                props.isDisabled(false);
                break;
            case 3:
                setRangeData([...rangeData, date.toLocaleDateString().replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1')])
                break
            default:
                props.updateDataDate({
                    date: date.toLocaleDateString()
                })
                props.setActive(false);
                break;
        }
    }
    //Сортировка периода выбранных дат, отображаемых в календаре
    const SortingRangeData = () => {
        if (rangeData[0] <= rangeData[1]) {
            return (rangeData[0]?.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') + 
                " - " + 
                rangeData[1]?.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1'))
        } else  {
            return (rangeData[1]?.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1') + 
                " - " + 
                rangeData[0]?.replace(/(\d*).(\d*).(\d*)/, '$3.$2.$1'))
        }
    }

    const dateСomparison = (dateOfMonth) => {
        let date1 = Date.UTC(dateOfMonth.getFullYear(), dateOfMonth.getMonth(), dateOfMonth.getDate())
        let date2 = Date.UTC(borderDateToPastDays.getFullYear(), borderDateToPastDays.getMonth(), borderDateToPastDays.getDate())
        return date1 < date2
    }

    return (
        <div className="calendar" style={props.stylesCalendar ? {width: props.stylesCalendar.width} : null}>
            <div className="calendar__navigation" style={props.styles ? 
                {width: props.styles.width, justifyContent: props.styles.justifyContent, margin: props.styles.margin} : null}>
                <div className={disabledBtnToMovePrev ? 
                    'calendar__arrow calendar__prevmonth-btn disabled-arrow' : 
                    "calendar__arrow calendar__prevmonth-btn"} 
                    onClick={() => handlePrevMonthButtonClick()}/>
                {
                    typeOfCalendar === 3 ? null :
                    <p className="calendar__navigation-current-date">{selectedDate ? selectedDate : currentDate?.getDate()}</p> 
                }
                <ul style={{display: 'flex'}}>
                    <li className="calendar__select div-select" ref={refCloseIsOpenSelectMonth}>
                        <span  className="calendar__select-month" 
                            
                            style={typeOfCalendar === 3 ? {marginLeft: '0px', width: 'auto'} : {zIndex: 9999}}
                            onClick={() => setIsOpenSelectMonth(!isOpenSelectMonth) & setIsOpenSelectYear(false)}>
                                {monthNames[date?.getMonth()]}
                        </span>
                        { isOpenSelectMonth ? 
                            <ul className="calendar__select-list" >
                                {monthNames.map((keyMonth, index) =>
                                    <li className='calendar__select-item'
                                        key={keyMonth} 
                                        value={index} 
                                        onClick={() => handleSelectChange(index, selectedYear) & setIsOpenSelectMonth(!isOpenSelectMonth)}>
                                                {keyMonth}
                                        <div className={date?.getMonth() === index ? 'selected-item' : 'hide'}></div>
                                    </li>
                                )}
                            </ul> :
                            null
                        }
                    </li>
                    <li className="calendar__select div-select" ref={refCloseIsOpenSelectYear}>
                        <span className="calendar__select-year" 
                            onClick={() => setIsOpenSelectYear(!isOpenSelectYear) & setIsOpenSelectMonth(false)}>
                                {date?.getFullYear()}
                        </span>
                        { isOpenSelectYear ? location.pathname === '/profile' 
                            ?
                            <ul className="calendar__select-list-birthday">
                                {birthDayYears.map((birthDayYears) =>
                                <li className="calendar__select-item" 
                                    key={birthDayYears} 
                                    value={birthDayYears} 
                                    onClick={() => handleSelectChange(selectedMonth, birthDayYears) & setIsOpenSelectYear(!isOpenSelectYear)}>
                                            {birthDayYears}
                                </li>)}
                            </ul>
                            :
                            <ul className="calendar__select-list">
                                {years.map((keyYear) =>
                                    <li className='calendar__select-item' 
                                        key={keyYear} 
                                        value={keyYear} 
                                        onClick={() => handleSelectChange(selectedMonth, keyYear)}>
                                        {keyYear}
                                        <div className={date?.getFullYear() === keyYear ? 'selected-item' : 'hide'}></div>
                                    </li>
                                )}
                            </ul> :
                            null
                        }
                    </li>
                </ul>
                <div className={disabledBtnToMoveNext ? 
                    'calendar__arrow calendar__nexmonth-btn disabled-arrow' : 
                    "calendar__arrow calendar__nexmonth-btn"} 
                    onClick={() => handleNextMonthButtonClick()}/>
            </div>
            {
                typeOfCalendar === 3 ?
                    <div style={{maxWidth: '180px', height: '22px'}}>
                        {
                            rangeData ? 
                            <p style={{color: '#94A3BD', fontSize: '16px'}}>{SortingRangeData()}</p>
                             : null
                        }
                    </div> : null

            }
            <table className="calendar__table-container" style={props.stylesCalendarTable ?
                {width: props.stylesCalendarTable.width, 
                height: props.stylesCalendarTable.height, 
                margin: props.stylesCalendarTable.margin} : null}>
                <thead className="calendar__table-container__header">
                    <tr>
                        {weekDayNames.map((name) =>
                            <th className="calendar__week-names" 
                                key={name} 
                                value={name}>
                                    {name}
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody className="calendar__table-container__body" style={props.stylesCalendarTableBody ?
                {height: props.stylesCalendarTableBody.height} : null}>
                    {monthDate?.map((week, index) =>
                        <tr key={index}>
                            {week.map((date, index) => date ?
                             <td style={props.stylesCalendarMonthDate ? 
                                {width: props.stylesCalendarMonthDate.width, height: props.stylesCalendarMonthDate.height} : null} 
                                className={!props.disabledPastDays  ? 
                                "calendar__month-date" :
                                dateСomparison(date) ?
                                // date.valueOf() <= borderDateToPastDays.valueOf() ? 
                                    "calendar__month-date past-days" : 
                                    "calendar__month-date"} 
                                    onClick={() =>  handleDayClick(date)} 
                                    key={index}>
                                        {(date.getDate() === currentDate?.getDate()) && 
                                        (date.getMonth() === currentDate?.getMonth()) && 
                                        (date.getFullYear() === currentDate?.getFullYear()) 
                                    ? 
                                    
                                    <p className={onIncludes(date) ? 
                                        "selected-day" : inRange(date) ?  
                                        'in-range' : date.getDate() === selectedDay ? 
                                        "selected-day" : date.toLocaleDateString() === currentContext ? 
                                        "selected-day" : "current-date"} 
                                        // onClick={() => handleDayClick(date)}
                                        style={inRange(date) ? {fontWeight: 700} : null}
                                        onClick={() => typeOfCalendar === 3 ? handleDayClick(date) : setSelectedDay(date.getDate())}>
                                            {date.getDate()}
                                    </p> 
                                    : (typeOfCalendar === 1) || (typeOfCalendar === 2) || (typeOfCalendar === '') ?
                                        <p className={date.getDate() === selectedDay ? 
                                            "selected-day" : date.toLocaleDateString() === currentContext ? 'selected-day' : null} 
                                            onClick={() => setSelectedDay(date.getDate())}>
                                                {date.getDate()}
                                        </p> :
                                        <p className={onIncludes(date) ? 
                                            "selected-day" : inRange(date) ?  'in-range' : null}
                                            onClick={() =>  handleDayClick(date)}>
                                                {date.getDate()}
                                        </p>
                                    }
                            </td>
                             :
                             <td key={index}/>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}