import { Dispatch } from 'redux';
import { TaskTabelActionTypes, TaskTableAction } from '../../Pages/SettingsPage/widgets/TaskStats/TaskTableSection/store/TaskTableSectionStore'
import request from '../../Requests/requests';

export const getTaskTable = (dateStart: string, dateEnd: string, orderBy: string, pageIndex: number, pageSize: number) => {
  return async (dispatch: Dispatch<TaskTableAction>) => {
    dispatch({ type: TaskTabelActionTypes.TASK_TABLE_LOADING });

    try {
      const response = await request.get('Statistics/CompletedTasksStatistic', {
        params: {
          UserId: localStorage.getItem('token'),
          DateStart: dateStart,
          DateEnd: dateEnd,
          OrderBy: orderBy,
          PageIndex: pageIndex,
          PageSize: pageSize
        }
      });
      const data = await response.data;
      if (response.status === 200) {
        dispatch({ type: TaskTabelActionTypes.TASK_TABLE_SUCCESS, payload: data });
      } else {
        throw new Error(data.error);
      }
    } catch (error: any) {
      dispatch({ type: TaskTabelActionTypes.TASK_TABLE_ERROR, payload: error.message });
    }
  }
}

export const loadTaskTable = (dateStart: string, dateEnd: string, orderBy: string, pageIndex: number, pageSize: number) => {
  return async (dispatch: Dispatch<TaskTableAction>) => {
    dispatch({ type: TaskTabelActionTypes.TASK_TABLE_LOADING });

    try {
      const response = await request.get('Statistics/CompletedTasksStatistic', {
        params: {
          UserId: localStorage.getItem('token'),
          DateStart: dateStart,
          DateEnd: dateEnd,
          OrderBy: orderBy,
          PageIndex: pageIndex,
          PageSize: pageSize
        }
      });
      const data = response.data;
      if (response.status === 200) {
        dispatch({ type: TaskTabelActionTypes.TASK_TABLE_LOAD, payload: data });
      } else {
        throw new Error(data.error);
      }
    } catch (error: any) {
      dispatch({ type: TaskTabelActionTypes.TASK_TABLE_ERROR, payload: error.message });
    }
  }
}