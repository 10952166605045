import request from "../Requests/requests";
export const editTodo = (payload) => {
  return async dispatch => {
    await request.put('/Task/Update', {
      taskId: payload.id,
      userId: payload.userId,
      title: payload.title !== null ? payload.title.replace(/(<([^>]+)>)/gi, '') : payload.title,
      description: payload.description !== null ? payload.description : payload.description,
      priority: payload.priority,
      hours: payload.hours,
      minutes: payload.minutes,
      date: payload.date,
      time: payload.time,
      tagsId: payload.tags?.map((tag) => tag.tagId),
      isOpened: payload.isOpened,
    })
  }
}