import request from "../../../../Requests/requests";
import { useState, useEffect, useRef, useMemo } from "react";
import { useOutsideClick } from "../../../../customHooks/useOutsideClick";
import { modalWindowContext } from "../../../../Context/modalWindowContext";
import getMonthData from "../../../../Calendar/Calendar";
import ChangeEmailPage from "../../../../ModalWindow/ModalWindowChangeEmail";
import ChangePasswordPage from "../../../../ModalWindow/ModalWindowChangePassword";

export default function UserDatePage (props) {
    const currentDate = useMemo(() => new Date(), []) ;
    const [isSelectedBDay, setIsSelectedBDay] = useState(null);
    const [isSelectedBMonth, setIsSelectedBMonth] = useState(null);
    const [isSelectedBYear, setIsSelectedBYear] = useState(null);
    const [nickname, setNickname] = useState();
    const [name, setName] = useState();
    const [lastName, setLastName] = useState();
    // const [birthday, setBirthday] = useState();
    const [email, setEmail] = useState();
    const [modalShowEmail, setModalEmail] = useState(false);
    const [modalShowPassword, setModalPassword] = useState(false);
    const [position, setPosition] = useState();
    const [checkChangingWords, setCheckChangingWords] = useState({});
    const [menuActivDatePicker, setMenuActivDatePicker] = useState(false);
    const [countWord, setCountWord] = useState();
    const [wordLimitDescr, setWordLimitDescr] = useState({
        isToogle: false,
        path: null
    });
    const monthNames = ['Январь','Февраль','Март','Апрель','Май','Июнь','Июль','Август','Сентябрь','Октябрь','Ноябрь','Декабрь'];
    const birthDayYears = [];
    for (let i = 1924; i <= currentDate.getFullYear() - 10; i++) {
        birthDayYears.push(i);
    }
    const [isOpenDateMonthPicker, setIsOpenDateMonthPicker] = useState(false);
    const [isOpenDateYearPicker, setIsOpenDateYearPicker] = useState(false);
    const [isOpenDateDayPicker, setIsOpenDateDayPicker] = useState(false);
    const [dateOfBirthDay, setDateOfBirthDay] = useState(null);
    const [dateOfBirthMonth, setDateOfBirthMonth] = useState(null);
    const [dateOfBirthYear, setDateOfBirthYear] = useState(null);
    // const dateOfBirthSplited = [dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear]
    const monthDate =  getMonthData(dateOfBirthYear, dateOfBirthMonth)
    const maxWordLength = 100
    
    const refCloseDatePicker = useRef();
    useOutsideClick(refCloseDatePicker, () => setMenuActivDatePicker(false), menuActivDatePicker);

    const refCloseDateDayPicker = useRef();
    useOutsideClick(refCloseDateDayPicker, () => setIsOpenDateDayPicker(false), isOpenDateDayPicker);
    
    const refCloseDateMonthPicker = useRef();
    useOutsideClick(refCloseDateMonthPicker, () => setIsOpenDateMonthPicker(false), isOpenDateMonthPicker);

    const refCloseDateYearPicker = useRef();
    useOutsideClick(refCloseDateYearPicker, () => setIsOpenDateYearPicker(false), isOpenDateYearPicker);

    // const updateDataDate = (payload) => {
    //     setBirthday(payload.date)
    // }

    const updateDataEmail = (payload) => {
        setEmail(payload)
    }
    
    useEffect(() => {
        request.get('/Account/User/', {
            params: {UserId: localStorage.getItem('token') }
        })
        .then(response =>{
            const dataUser = response.data;
            setNickname(dataUser.nickname);
            setName(dataUser.name);
            setLastName(dataUser.lastname);
            // setBirthday(dataUser.birthday.split('T')[0].replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1'));
            setEmail(dataUser.email);
            setPosition(dataUser.position);
            setDateOfBirthDay((dataUser.birthday.split('T')[0].replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1').slice(0,2)))
            setDateOfBirthMonth(dataUser.birthday.split('T')[0].replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1').slice(3,5) - 1)
            setDateOfBirthYear(Number(dataUser.birthday.split('T')[0].replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1').slice(6,10)))
            setCheckChangingWords({
                nickname: dataUser.nickname,
                name: dataUser.name,
                lastname: dataUser.lastname,
                birthday: dataUser.birthday.split('T')[0].replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1'),
                email: dataUser.email,
                position: dataUser.position
            })
        })
    }, [])

    function wordLimit(e) {
        if (e.target.id === 'name') {
            setCountWord(e.target.value.length)
            setWordLimitDescr({isToogle: true, 
                                path: e.target.id})
        } else if (e.target.id === 'lastname') {
            setCountWord(e.target.value.length)
            setWordLimitDescr({isToogle: true, 
                                path: e.target.id})
        } else if (e.target.id === 'nickname') {
            setCountWord(e.target.value.length)
            setWordLimitDescr({isToogle: true, 
                                path: e.target.id})
        } else if (e.target.id === 'post') {
            setCountWord(e.target.value.length)
            setWordLimitDescr({isToogle: true, 
                                path: e.target.id})
        }
    }

    function unDisabledBtn(e) {  //Проверка кнопки "Сохранить" на активность. Если одна из переменных отличается от исходной, то кнопка становится активной.
        if (e.target.id === 'name') {
            // setName(e.target.value)
            wordLimit(e)
            if (checkChangingWords.name === e.target.value) {
                props.isDisabled(true)
            } else {
                checkBeforePush(e)
            }
        } else if (e.target.id === 'lastname') {
            setLastName(e.target.value)
            wordLimit(e)
            if (checkChangingWords.lastName === e.target.value) {
                props.isDisabled(true)
            } else {
                checkBeforePush(e)
            }
        } else if (e.target.id === 'nickname') {
            setNickname(e.target.value)
            wordLimit(e)
            if (checkChangingWords.nickname === e.target.value) {
                props.isDisabled(true)
            } else {
                checkBeforePush(e)
            }
        } else if (e.target.id === 'post') {
            setPosition(e.target.value)
            wordLimit(e)
            if (checkChangingWords.position === e.target.value) {
                props.isDisabled(true)
            } else {
                checkBeforePush(e)
            }
        }
    }

    const joinBirthdayDate = (dateOfBirthDay, dateOfBirthMonth, dateOfBirthYear, e) => {
        // eslint-disable-next-line
        // setBirthday(`${dateOfBirthDay <= 9 ? '0' + dateOfBirthDay : dateOfBirthDay}.${dateOfBirthMonth+1 <= 9 ? '0' + `${dateOfBirthMonth+1}` : dateOfBirthMonth+1}.${dateOfBirthYear}`);
        props.isDisabled(true)
        checkBeforePush(e, `${dateOfBirthDay <= 9 ? '0' | dateOfBirthDay : dateOfBirthDay}.${dateOfBirthMonth+1 <= 9 ? '0' | `${dateOfBirthMonth+1}` : dateOfBirthMonth+1}.${dateOfBirthYear}`)
        // props.isDisabled(false)
        // props.isChangedFunction(true)
        // props.updateDataUserSetting({
        //     nickname: nickname,
        //     name: name,
        //     lastname: lastName,
        //     birthday: `${dateOfBirthDay <= 9 ? '0' + dateOfBirthDay : dateOfBirthDay}.${dateOfBirthMonth+1}.${dateOfBirthYear}`,
        //     email: email,
        //     position: position
        // })
    }

    function checkBeforePush(e, value) {
        props.isDisabled(false)
        props.isChangedFunction(true)
        props.fromClose('userDatePage')
        props.updateDataUserSetting({
            nickname: e.target.id === 'nickname' ? e.target.value : nickname,
            name: e.target.id === 'name' ? e.target.value : name,
            lastname: e.target.id === 'lastname' ? e.target.value : lastName,
            birthday: value,
            email: email,
            position: e.target.id === 'post' ? e.target.value : position
        })
    }
    
    return(
        <>
        <div>
            <form>
                <ul className="settings-profile">
                    <li className="settings-profile__item-name">
                        <div className="settings-profile-name-container">
                            <p className="settings-profile__title">Имя</p>
                            <input type="text" 
                                    id="name" 
                                    name="name" 
                                    value={name}
                                    maxLength={maxWordLength}
                                    autoComplete="off" 
                                    className="settings-profile__name"
                                    onFocus={e => wordLimit(e)}
                                    onBlur={() => setWordLimitDescr({isToogle: false})} 
                                    onChange={e => setName(e.target.value) & unDisabledBtn(e)}/>
                            <p className={wordLimitDescr.isToogle ? wordLimitDescr.path === 'name' ? 'settings-profile__item-description' : 'hide' : 'hide'}>Лимит символов: {countWord} из 100</p>
                        </div>
                        <div className="settings-profile-lastname-container">
                            <p className="settings-profile__title">Фамилия</p>
                            <input type="text" 
                                    id="lastname" 
                                    name="lastname" 
                                    value={lastName}
                                    maxLength={maxWordLength}
                                    autoComplete="off" 
                                    className="settings-profile__name"
                                    onFocus={e => wordLimit(e)}
                                    onBlur={() => setWordLimitDescr({isToogle: false})} 
                                    onChange={e => unDisabledBtn(e)}/>
                            <p className={wordLimitDescr.isToogle ? wordLimitDescr.path === 'lastname' ? 'settings-profile__item-description' : 'hide' : 'hide'}>Лимит символов: {countWord} из 100</p>
                        </div>
                    </li>
                    <li className="settings-profile__item">
                        <p className="settings-profile__title">Никнейм</p>
                        <input type="text" 
                                id="nickname" 
                                name="nickname" 
                                value={nickname}
                                maxLength={maxWordLength}
                                autoComplete="off" 
                                className="settings-profile__other"
                                onFocus={e => wordLimit(e)}
                                onBlur={() => setWordLimitDescr({isToogle: false})}  
                                onChange={e => unDisabledBtn(e)}/>
                        <p className={wordLimitDescr.isToogle ? wordLimitDescr.path === 'nickname' ? 'settings-profile__item-description' : 'hide' : 'hide'}>Лимит символов: {countWord} из 100</p>
                    </li>
                    <li className="settings-profile__item">
                        <p className="settings-profile__title">Email</p>
                        <input type="email" 
                                id="email" 
                                name="email" 
                                value={email} 
                                className="settings-profile__other" 
                                readOnly 
                                onClick={() => setModalEmail(true)}/>
                    </li>
                    <li className="settings-profile__item">
                        <p className="settings-profile__title">Пароль</p>
                        <input type="password" 
                                id="password" 
                                name="password" 
                                value="*****" 
                                className="settings-profile__other" 
                                readOnly 
                                onClick={() => setModalPassword(true)}/>
                    </li>
                    <li className="settings-profile__item">
                        <p className="settings-profile__title">Дата рождения</p>
                        <ul className="settings-profile__datebirth-control-list">
                            <li className="settings-profile__datebirth-control-item">
                                <div className="settings-profile__datebirth date-of-birth-day"
                                    ref={refCloseDateDayPicker}
                                    onClick={() => setIsOpenDateDayPicker(!isOpenDateDayPicker)}>
                                    <input type="text" id="birthdayDay" className="settings-profile__datebirth-input" 
                                        value={dateOfBirthDay}
                                        readOnly
                                        />
                                    <span className={isOpenDateDayPicker ? 
                                        "settings-profile__datebirth-arrow datebirth-arrow__active" : 
                                        "settings-profile__datebirth-arrow"}/>                                
                                </div>
                                <div className="settings-profile__datebirth__mobile date-of-birth-day">
                                    <select name='B-day' 
                                        id='B-day' 
                                        className="input-select-date" 
                                        value={dateOfBirthDay} 
                                        onChange={e => setDateOfBirthDay(Number(e.target.value))
                                                        & joinBirthdayDate(Number(e.target.value), dateOfBirthMonth, dateOfBirthYear, e)
                                                        & setIsOpenDateDayPicker(false)
                                                        & setIsSelectedBDay(Number(e.target.value))}>
                                                {monthDate.map((index) => 
                                                    index.map((day, index) => day ? 
                                                    <option className={day.getDate() === isSelectedBDay ? 
                                                        'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                        key={index}
                                                        value={day.getDate()}>
                                                        {day.getDate()}
                                                    </option> 
                                                    : null)
                                                )}
                                    </select>    
                                    <span className="settings-profile__datebirth-arrow"/>
                                </div>
                                {   
                                    isOpenDateDayPicker ?
                                        <ul className="calendar__select-list-birthday settings-profile__datebirth-list"
                                            style={{width: '63px', height: '148px', marginLeft: '28px'}}>
                                            {monthDate.map((index) => 
                                                index.map((day, index) => day ? 
                                                <li className={day.getDate() === isSelectedBDay ? 'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                    key={index}
                                                    onClick={(e) => setDateOfBirthDay(day.getDate()) 
                                                        & joinBirthdayDate(day.getDate(), dateOfBirthMonth, dateOfBirthYear, e)
                                                        & setIsOpenDateDayPicker(false)
                                                        & setIsSelectedBDay(day.getDate())}>
                                                    {day.getDate()}
                                                </li> 
                                                : null)
                                            )}
                                        </ul>
                                    : null
                                }
                            </li>
                            <li className="settings-profile__datebirth-control-item">
                                <div className="settings-profile__datebirth date-of-birth-month"
                                    ref={refCloseDateMonthPicker} 
                                    onClick={() => setIsOpenDateMonthPicker(!isOpenDateMonthPicker)}>
                                    <input type="text" 
                                        id="birthdayMonth" 
                                        className="settings-profile__datebirth-input" 
                                        value={monthNames[dateOfBirthMonth]}
                                        readOnly
                                        />
                                    <span className={isOpenDateMonthPicker ? 
                                            "settings-profile__datebirth-arrow datebirth-arrow__active" : 
                                            "settings-profile__datebirth-arrow"}/> 
                                </div>
                                <div className="settings-profile__datebirth__mobile date-of-birth-month">
                                    <select name='M-day' 
                                            id='M-day' 
                                            className="input-select-date" 
                                            value={dateOfBirthMonth}
                                            onChange={(e) => setDateOfBirthMonth(Number(e.target.value)) 
                                                & joinBirthdayDate(dateOfBirthDay, Number(e.target.value), dateOfBirthYear, e) 
                                                & setIsOpenDateMonthPicker(false)
                                                & setIsSelectedBMonth(monthNames[Number(e.target.value)])}>
                                                    {monthNames.map((keyMonth, index) =>
                                                    <option className={keyMonth === isSelectedBMonth ? 'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                        key={keyMonth} 
                                                        value={index}
                                                        onClick={(e) => setDateOfBirthMonth(index) 
                                                            & joinBirthdayDate(dateOfBirthDay, index, dateOfBirthYear, e)
                                                            & setIsOpenDateMonthPicker(false)
                                                            & setIsSelectedBMonth(keyMonth)}
                                                        >
                                                                {keyMonth}
                                                    </option>
                                            )}
                                    </select>
                                    <span className="settings-profile__datebirth-arrow"/>                
                                </div>
                                {
                                    isOpenDateMonthPicker ?
                                        <ul className="calendar__select-list settings-profile__datebirth-list"
                                            style={{width: '110px', height: '148px', overflowX: 'hidden', marginLeft: '23px'}}>
                                            {monthNames.map((keyMonth, index) =>
                                                <li className={keyMonth === isSelectedBMonth ? 'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                    key={keyMonth} 
                                                    value={index}
                                                    onClick={(e) => setDateOfBirthMonth(index) 
                                                        & joinBirthdayDate(dateOfBirthDay, index, dateOfBirthYear, e)
                                                        & setIsOpenDateMonthPicker(false)
                                                        & setIsSelectedBMonth(keyMonth)}>
                                                        {keyMonth}
                                                </li>
                                            )}
                                        </ul>
                                    : null
                                }
                            </li>
                            <li className="settings-profile__datebirth-control-item">
                                <div className="settings-profile__datebirth date-of-birth-year"
                                    ref={refCloseDateYearPicker} 
                                    onClick={() => setIsOpenDateYearPicker(!isOpenDateYearPicker)}>
                                    <input type="text" 
                                        id="birthdayYear"
                                        className="settings-profile__datebirth-input"  
                                        value={dateOfBirthYear}
                                        readOnly
                                    />
                                    <span className={isOpenDateYearPicker ? 
                                            "settings-profile__datebirth-arrow datebirth-arrow__active" : 
                                            "settings-profile__datebirth-arrow"}/>
                                </div>
                                <div className="settings-profile__datebirth__mobile date-of-birth-year">
                                    <select name='Y-day' 
                                            id='Y-day' 
                                            className="input-select-date"
                                            value={dateOfBirthYear}
                                            onChange={(e) => setDateOfBirthYear(Number(e.target.value)) 
                                                & joinBirthdayDate(dateOfBirthDay, dateOfBirthMonth, Number(e.target.value), e)
                                                & setIsOpenDateYearPicker(false)
                                                & setIsSelectedBYear(Number(e.target.value))}>
                                                    {birthDayYears.map((birthDayYears, index) =>
                                                    <option className={index === isSelectedBYear ? 'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                        key={index} 
                                                        value={birthDayYears}>
                                                                {birthDayYears}
                                                    </option>
                                                )}
                                        </select>
                                        <span className="settings-profile__datebirth-arrow"/>
                                </div>
                                {
                                    isOpenDateYearPicker ? 
                                        <ul className="calendar__select-list-birthday settings-profile__datebirth-list"
                                            style={{width: '84px', height: '148px', marginLeft: '23px'}}>
                                            {birthDayYears.map((birthDayYears, index) =>
                                            <li className={index === isSelectedBYear ? 'calendar__select-item selected-item' : 'calendar__select-item'} 
                                                key={index} 
                                                value={birthDayYears}
                                                onClick={(e) => setDateOfBirthYear(birthDayYears) 
                                                    & joinBirthdayDate(dateOfBirthDay, dateOfBirthMonth, birthDayYears, e)
                                                    & setIsOpenDateYearPicker(false)
                                                    & setIsSelectedBYear(index)}>
                                                        {birthDayYears}
                                            </li>)}
                                        </ul>
                                    : null
                                }
                            </li>
                        </ul>
                    </li>
                    <li className="settings-profile__item">
                        <p className="settings-profile__title">Должность</p>
                        <input type="text" 
                                id="post" 
                                name="post" 
                                value={position}
                                maxLength={maxWordLength}
                                autoComplete="off" 
                                className="settings-profile__other" 
                                placeholder="Должность"
                                onFocus={e => wordLimit(e)}
                                onBlur={() => setWordLimitDescr({isToogle: false})}
                                onChange={e => unDisabledBtn(e)}/>
                        <p className={wordLimitDescr.isToogle ? wordLimitDescr.path === 'post' ? 'settings-profile__item-description' : 'hide' : 'hide'}>Лимит символов: {countWord} из 100</p>
                    </li>
                </ul>
                {modalShowEmail ? 
                <modalWindowContext.Provider value={[modalShowEmail, setModalEmail]}>
                    <ChangeEmailPage userEmail={email}
                                    updateDataEmail={updateDataEmail}
                                    isSuccesAlert={props.isSuccesAlert} />
                </modalWindowContext.Provider> : null }
                {modalShowPassword ? 
                <modalWindowContext.Provider value={[modalShowPassword, setModalPassword]}>
                    <ChangePasswordPage isSuccesAlert={props.isSuccesAlert}/>
                </modalWindowContext.Provider> : null }
            </form>
        </div>
        </>
    )
}