import React, { ReactElement, useRef } from "react";
import './DropDown.css'
import { useOutsideClick } from "../../customHooks/useOutsideClick";

interface DropDownProps {
    children: ReactElement,
    isActive: boolean,
    updateIsActive: Function,
    refLink?: any,
    position?: object 
}

export default function DropDown({children, isActive, updateIsActive, refLink, position}: DropDownProps) {
    const refDropDown = useRef(null)
    useOutsideClick(refDropDown, () => updateIsActive(false), isActive)
    
    return (
        <div ref={refLink ?? refDropDown} style={isActive ? position : {}} className={isActive ? "DropDown" : "hidden"} >
            {children}
        </div>
    )
}