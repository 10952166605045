
import React from "react";
import TaskStats from "../../Pages/SettingsPage/widgets/TaskStats/TaskStats";

export default function TaskStatsPage(props) {
  return (
    <>
      <TaskStats getUrlData={props.getUrlData}/>
    </>
  );
};