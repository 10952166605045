const defaultState = {
    repeatTemplate: [],
}

const ADD_TEMPLATE = "ADD_TEMPLATE"
const REMOVE_TEMPLATE = "REMOVE_TEMPLATE"
const LOAD_DATA_TEMPLATES= "LOAD_DATA_TEMPLATES"

export const repeatTemplateReducer = (state = defaultState, action) => {
    switch(action.type) {
      case ADD_TEMPLATE:
        return {...state, repeatTemplate: [...state.repeatTemplate, action.payload]} 
      case REMOVE_TEMPLATE:
        return {...state, repeatTemplate: state.repeatTemplate.filter(repeatTemplate => repeatTemplate.id !== action.payload)}
      case LOAD_DATA_TEMPLATES:
        return {...state, repeatTemplate: [...action.payload]}
      default:
        return state
    }
  }

export const addTemplate = (payload) => ({type: ADD_TEMPLATE, payload})
export const removeTemplate = (payload) => ({type: REMOVE_TEMPLATE, payload})
export const loadTemplates = (payload) => ({type: LOAD_DATA_TEMPLATES, payload})