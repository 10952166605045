import React, { ReactElement, useEffect, useState } from "react";
import {ReactComponent as CloseInfo} from "../../icons/svg/cross_to_close_info.svg"
import './index.css';

interface TooltipProps {
    children?: ReactElement,
    tooltipHeader: string,
    tooltipDescription: string,
    tooltipComponent?: ReactElement,
    stylesContainer?: object,
    stylesList: object,
    stylesListArrow: object,
    autoOpen?: boolean,
    active: boolean,
}

export default function ToolTipComponent({children, tooltipHeader, tooltipDescription, tooltipComponent, stylesContainer, stylesList, stylesListArrow, autoOpen, active}: TooltipProps){
    const [showToolTip, setShowToolTip] = useState<boolean>();
    const [isTimeout, setIsTimeout] = useState(false);
    useEffect(() => {
        return setShowToolTip(autoOpen);
    }, [autoOpen])

    if (autoOpen) {
        if (!isTimeout) {
            setTimeout(() => {
                setShowToolTip(false);
                setIsTimeout(true);                
            }, 3500);
        }
    }

    function fadeOutTooltip(delay: number) {
        if (active) {
            const getDiv = document.querySelector('.tooltip_content');
            getDiv?.classList.toggle('hidden_tooltip')
            setTimeout(() => {
                setShowToolTip(false)
            }, delay);
        }
    }

    return(
        <div className="tooltip_conatiner" style={stylesContainer}
            onClick={(e) => e.preventDefault()}
            onMouseEnter={() => active ? setShowToolTip(true) : null}
            onMouseLeave={() => active ? fadeOutTooltip(300) : null}>
            {children}
            {
                showToolTip &&
                <div className="tooltip_content" style={stylesList}>
                    <div className="tooltipArrow" style={stylesListArrow}/>
                    {
                        tooltipComponent ? tooltipComponent :
                        <>
                            <h1>{tooltipHeader}</h1>
                            <p>{tooltipDescription}</p>
                        </>
                    }
                    <div className="tooltipClose" onClick={() => {setShowToolTip(false)}}>
                        <CloseInfo style={{width: '9px', height: '9px'}}/>
                    </div>
                </div>
            }
            
        </div>
    )
}