import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux/es/exports';
import { loadRunTimeResults } from "../store/RunTimeTaskResults/RunTimeResults";
import { getTodosIncoming } from "../asyncActions/getIncomingTodos";
import TodosAddApp from "./TodosAddApp";
// import GroupChangeMenu from "../GroupTaskEditing/GroupChangeMenu";
import Filter from "../Filter/Filter";
import TodoList from "./TodosList";
import Loader from "../Loader/Lodaer";
import { ReactComponent as ModalTitleImg } from "../icons/svg/submenu_ic/filled-active.svg";
import { ReactComponent as AddTaskPlus } from "../icons/svg/add-task-plus.svg";
import { ReactComponent as AddTaskPlusMobile } from "../icons/svg/add-task-plus__mobile.svg";
import { addOnContextState } from "../store/contextMenuTaskReducer";
import PaginationBtn from "../UIcomponents/PaginationBtn/PaginationBtn";
import { useConnectionHubContext } from "../Context/connectionHubContext";
import { getTimeAllTodos } from "../asyncActions/getTimeAllTodos";
import { getCountTime } from "../asyncActions/StatisticsActions/getCountTime";


export default function TodoListIncoming({ setIcon }) {

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const todosIncoming = useSelector(state => state.todosIncoming.todosIncoming);
  const [isOpen, setIsOpen] = useState(false);
  const updateData = (value) => {
    setIsOpen(value)
  }
  const [dataLoad, setDataLoad] = useState({ pageSize: 10, pageIndex: 1 });
  const [hasNextPage, setHasNextPage] = useState(true);
  const paginationBtnData = {
    title: 'Показать еще'
  }
  const connectionHub = useConnectionHubContext()

  useEffect(() => {
    connectionHub.updateHub?.on("Receive", (userId) => {
      dispatch(getTodosIncoming(dataLoad, setHasNextPage));
      dispatch(getTimeAllTodos());
      dispatch(getCountTime());
    });
    return () => {
      connectionHub.updateHub?.off("Receive")
    }

  }, [connectionHub, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const resetPageIndex = () => {
      setDataLoad({ ...dataLoad, pageIndex: 1 })
      sessionStorage.setItem('key_FilterMethod', 6);
      sessionStorage.setItem('key_FilterOrder', null);
    }

    window.addEventListener('beforeunload', resetPageIndex)

    return () => {
      window.removeEventListener('beforeunload', resetPageIndex)
    }
  }, [dataLoad])

  useEffect(() => {
    dispatch(getTodosIncoming(dataLoad, setHasNextPage));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    setLoading(false);
    setIcon(<ModalTitleImg style={{ width: '14px' }} />)
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [getTodosIncoming, dataLoad])

  useEffect(() => {
    document.addEventListener("keydown", checkFastCombinations);
    return () => {
      document.removeEventListener("keydown", checkFastCombinations);
    };
  });
  function checkFastCombinations(e) {
    // console.log(e.keyCode);
    if (e.altKey && e.keyCode === 77) { //shift + m - открыть/закрыть меню добавления задачи
      setIsOpen(!isOpen)
    }
    if (e.keyCode === 27) {
      setIsOpen(false)
    }
  }

  return (
        <div className="todo-list">
            <div className="todo-list__container">
                {/* {loading && <Loader></Loader>} */}
                {/* <TodosAddFunction></TodosAddFunction> */}
                <div id='todosMenu' className="task-actions-container to_the_end">
                    {/* <GroupChangeMenu /> */}
                        <div className="d-flex">
                            <div className={isOpen ? 'addTaskBtn__hide' : 'addTaskBtn'} onClick={() => setIsOpen(!isOpen)}>
                                <AddTaskPlus className="addTaskPlus"/>
                                <AddTaskPlusMobile className="addTaskPlus-Mobile"/>
                            </div>
                            <Filter />
                        </div>     
                </div>
                {isOpen && (
                    <div>
                        <TodosAddApp autofocus={true} updateData={updateData}></TodosAddApp>
                    </div>
                )}
                {
                    loading ? <Loader/> :
                    <ul className="todo-list__list">
                        {todosIncoming.length ? (<div>
                            {todosIncoming.map((todos, index) => {
                                dispatch(loadRunTimeResults({isValue: false, taskId: todos.id}))
                                dispatch(addOnContextState({contextIsOpen: false, contextTaskId: todos.id}))
                                return <TodoList 
                                            completeBtn 
                                            timerDisplay
                                            fromTodo
                                            pageIndex={dataLoad.pageIndex} 
                                            todos={todos}
                                            fromIncoming 
                                            key={index} />
                            }) }
                            {
                                hasNextPage ?
                                <div className="page_pagination">
                                    <PaginationBtn paginationBtnData={paginationBtnData} startIndex={dataLoad} handleClick={setDataLoad}></PaginationBtn>
                                </div>
                                : null
                            }
                                </div>) : loading ? null : (<p className="todo-list__is-null">Ваш список входящих задач пуст</p>)}
                    </ul>                    
                }
      </div>
    </div>
  )
}