import SubMenu from "../../Submenu/SubmenuApp";
import TodoListCalendar from "./TasksListCalendar";

const CalendarPage = ({setIcon}) => {
    return (
        <div className="main-container-view">
            <div className="submenu__container">
                <SubMenu />
            </div>
            <div className="page-container">
                <div>
                    <TodoListCalendar setIcon={setIcon}/>
                </div>
            </div>
        </div>
    );
}

export default CalendarPage;