export const regExpTime: RegExp = /(в)\s([0-1][0-9]|2[0-3]):[0-5][0-9]/gi;
export const regExpTimeTask: RegExp = /((на)? ([0-1]?[0-9]|2[0-3]) час(а|ов)?\s[0-5]?[0-9] минут(у|ы)?)/gi;
export const regExpToday: RegExp = /сегодня/gi;
export const regExpNoDate: RegExp = /без\sсрока/gi;
export const regExpTomorrow: RegExp = /(?<!(после|послезавтра))завтра/gi;
export const regExpAfterTomorrow: RegExp = /(?<!завтра)послезавтра/gi;
export const regExpDifferentDay: RegExp = /[0-2][0-9]\s(января|февраля|марта|апреля|мая|июня|июля|августа|сентября|октября|ноября|декабря)/gi;
export const regExpPriority: RegExp = /!![0-3]/g;
export const regExpTag: RegExp = /#|№/g;

export const regExpArray: RegExp [] = [
    regExpTime,
    regExpTimeTask,
    regExpToday,
    regExpNoDate,
    regExpTomorrow,
    regExpAfterTomorrow,
    regExpDifferentDay,
    regExpPriority,
    regExpTag,
]


