import React, { useEffect, useState, useRef } from "react";
import { Placeholder } from "react-bootstrap";
import request from "../../Requests/requests";
import '../../textEditor/quill.bubble.css';
import '../index.css';
import '../EditTaskModal/EditModalTask.css'
import { modalWindowContext } from "../../Context/modalWindowContext";
import { useOutsideClick } from "../../customHooks/useOutsideClick";
import { addCheckList } from "../../asyncActions/addCheckList";
import { addRunTime } from "../../asyncActions/RunTimeActions/addRunTime";
import { editTodo } from "../../asyncActions/editTodos";
import { ReactComponent as HideBtnSVG } from "../../icons/svg/ModalChangingTask/hidebtn.svg";
import { ReactComponent as TaskKebabSVG } from "../../icons/svg/ModalChangingTask/task-menu-icon.svg"
import { ReactComponent as TransferSVG } from "../../icons/svg/ModalChangingTask/TitleImg/transferIcon.svg";
import Modal from 'react-bootstrap/Modal';
import CheckList from "../../ChekList/CheckList";
import DDSelectDate from "../../DropDownMenu/SelectDateCalendar/DDSelectDate";
import DDSelectPriority from "../../DropDownMenu/SelectPriority/DDSelectPriority";
import DDSelectTags from "../../DropDownMenu/SelectTags/DDSelectTags";
import DDSelectTaskMenu from "../../DropDownMenu/SelectTaskMenu/DDSelectTaskMenu";
import DDSelectActionToCheckList from "../../DropDownMenu/SelectActionToCheckList/DDSelectActionToCheckList";
import ReactQuill from "react-quill";
import ModalWindowCloseWarning from "../ModalWindowCloseWarning";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Loader/Lodaer";
import TaskStopWatch from "../../TaskTimer/TaskStopWatch";
import { timerPush } from "../../asyncActions/TimerActions/timerPush";
import ConverterUTC from "../../ConverterUTC/ConverterUTC";
import ToolTipComponent from "../../UIcomponents/Tooltips/Tooltip";
import { useConnectionHubContext } from "../../Context/connectionHubContext";

const EditModalTask = ({ updateRunTimeFromTask, disabled, modalTitleImg, editTaskModalIcon, ...props }) => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [taskData, setTaskData] = useState({
    id: null,
    userId: null,
    title: null,
    description: null,
    isCompleted: false,
    isDelete: false,
    priority: null,
    hours: null,
    minutes: null,
    date: null,
    time: null,
    runTime: 0,
    isOpened: false,
    tags: []
  });
  const [title, setTitle] = useState('<p></p>');
  const [description, setDescription] = useState('<p></p>')
  const [unchangedTitle, setUnchangedTitle] = useState();
  const [unchangedDescription, setunchangedDescription] = useState();
  const [runTimeTask, setRunTimeTask] = useState(0);
  const [loading, setLoading] = useState(true);
  const settingsUser = useSelector(state => state.settings.settings)
  const stopWatchesData = useSelector(state => state.stopWatchesData.stopWatchesData);
  const [date, setDate] = useState();
  const [dateToday, setDateToday] = useState(new Date());
  const dateTomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000).toISOString().substring(0, 10).replace(/(\d*)-(\d*)-(\d*)/, '$3.$2.$1');
  const [taskDate, setTaskDate] = useState()
  const [actionArray, setActionArray] = useState();
  const [modalShowCheck, setModalShowCheck] = useState(false);
  const [flag, setFlag] = useState(false);
  const [checkListTitle, setCheckListTitle] = useState();
  const [hidebtn, setHidebtn] = useState('hideBtn-controls-to-modal');
  const [modalInputContainer, setModalInputContainer] = useState('modal-form__input-container')
  const [modalInputTitle, setModalInputTitle] = useState('modal-form__input-title');
  const [modalInputDescr, setModalInputDescr] = useState('modal-form__input-descr');
  const [disablePriority, setDisablePriority] = useState(false);
  const [windowScreen, setWindowScreen] = useState(window.screen.width);
  const [currentLenghtOfTitle, setCurrentLenghtOfTitle] = useState();
  const [isActiveCounter, setIsActiveCounter] = useState(false);
  const dataInputDate = [taskData.date, taskData.time]
  const maxLenghtOfTitle = 150;
  const [formIsChanged, setFormIsChanged] = useState(true);
  const connectionHub = useConnectionHubContext();
  const classPriorityArray = ['priority-input-1 priority__input-modalwindow ',
    'priority-input-2 priority__input-modalwindow',
    'priority-input-3 priority__input-modalwindow',
    'priority-input-0 priority__input-modalwindow '
  ]
  const namesPriorityArray = ['Высокий', 'Средний', 'Низкий', 'Без приоритета']
  const styles = {
    left: 0,
    transform: 'none'
  }
  const [widthInputTag, setWidthInputTag] = useState('207px');
  const [heightInputTag, setHeightInputTag] = useState('25px')
  const [menuActivDatePicker, setMenuActivDatePicker] = useState(false);
  const [menuActivPriorityPicker, setMenuActivPriorityPicker] = useState(false);
  const [menuActivTagsPicker, setMenuActivTagsPicker] = useState(false);
  const [menuActivTask, setMenuActivTask] = useState(false);
  const [controlBtnsCheckList, setControlBtnsCheckList] = useState(false);
  const stylesList = {
    backgroundColor: taskData?.transferCount >= 3 ? '#F8C440' : '#AC98F6', 
    top: '150%', left: windowScreen > 410 ? null : '-50%', 
    right: windowScreen > 410 ? '-5%' : '50%', 
    transform: windowScreen > 410 ? null : 'translateX(-50%)'
}
const stylesListArrow = {
    top: '-8%', 
    right: windowScreen > 410 ? '13%' : '30%'
}


  var toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'script': 'sub' }, { 'script': 'super' }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'align': [] }],
    ['link'],
    ['image'],
    ['clean']
  ];

  const module = {
    toolbar: toolbarOptions
  }
  const reactQuillRef = React.useRef();
  const reactQuillRefDescr = React.useRef();
  const refTransfer = useRef();
  const refCloseDatePicker = useRef();
  useOutsideClick(refCloseDatePicker, () => setMenuActivDatePicker(false), menuActivDatePicker);
  const refClosePriorityPicker = useRef();
  useOutsideClick(refClosePriorityPicker, () => setMenuActivPriorityPicker(false), menuActivPriorityPicker);
  const refCloseTagsPicker = useRef();
  useOutsideClick(refCloseTagsPicker, () => setMenuActivTagsPicker(false), menuActivTagsPicker);
  const refCloseControlBtnsCheckList = useRef();
  useOutsideClick(refCloseControlBtnsCheckList, () => setControlBtnsCheckList(false), controlBtnsCheckList);
  const refCloseTaskMenu = useRef();
  useOutsideClick(refCloseTaskMenu, () => setMenuActivTask(false), menuActivTask);



  useEffect(() => {
    setDate(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
    setDateToday(ConverterUTC({ method: 'object', settingsUser: settingsUser }))
  }, [settingsUser])

  useEffect(() => {
    setActionArray(stopWatchesData.find(el => el.taskId === taskData.id))
  }, [stopWatchesData]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    connectionHub.updateHub?.on("Receive", (userId) => {
      getTask()
    });
    return () => {
      connectionHub.updateHub?.off("Receive")
    }
  }, [connectionHub, dispatch]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateTaskList = () => {
    connectionHub.updateHub.invoke("Send", localStorage.getItem('token'))
      .catch(err => console.error(err));
  }

  const stopTimer = (startTime) => {
    try {
      connectionHub.timerHub?.invoke("Send", localStorage.getItem('token'), taskData.id, startTime, false)
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const handleUpdateScreenWidth = () => {
      setWindowScreen(window.screen.width)
    }

    window.addEventListener('resize', handleUpdateScreenWidth);

    return () => {
      window.removeEventListener('resize', handleUpdateScreenWidth);
    };
  }, [windowScreen])

  async function getTask() {
    try {
      const response = await request.get('/Task/Task', { params: { Id: params.id } })
      if (response.status === 204) {
        console.log('Данные не найдены');
      } else {
        setTaskData(response.data)
        setTitle(response.data.title)
        setTaskDate(response.data.date)
        setUnchangedTitle(response.data.title !== null ? `<p>${response.data.title}</p>` : response.data.title)
        setDescription(response.data.description)
        setunchangedDescription(response.data.description !== null ? `<p>${response.data.description}</p>` : response.data.description)
        setActionArray(stopWatchesData.find(actionArr => actionArr.taskId === response.data.id))
        setRunTimeTask(response.data.runTime)
        setLoading(false)
      }
    } catch (err) {
      console.log(console.err);
    }
  }
  useEffect(() => {
    getTask()
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (title === '<p><br></p>' && unchangedTitle === null) {
      setTitle(null)
    } else if (title === '<p><br></p>' && unchangedTitle === '<p></p>') {
      setTitle(<p></p>)
    } else if (description === '<p><br></p>' && unchangedDescription === '<p></p>') {
      setDescription('<p></p>')
    } else if (description === '<p><br></p>' && unchangedDescription === null) {
      setDescription(null)
    }
  }, [title, description, unchangedTitle, unchangedDescription])

  useEffect(() => {
    if ((unchangedDescription !== description) ||
      (unchangedTitle !== title)) {
      setFormIsChanged(false);
    }

    return () => {
      setFormIsChanged(true)
    }
  }, [unchangedDescription, unchangedTitle, description, title])

  const removeChanges = () => {
    setIsActiveCounter(false);
    setHidebtn('hideBtn-controls-to-modal');
    setDisablePriority(false);
    setFormIsChanged(true);
    setModalInputDescr('modal-form__input-descr__onblur');
    setModalInputTitle('modal-form__input-title__onblur');
    setModalInputContainer('modal-form__input-container');
    reactQuillRef.current.blur();
    reactQuillRefDescr.current.blur();
    setTimeout(() => {
      setTitle(unchangedTitle);
      setDescription(unchangedDescription);
      setTaskData({
        ...taskData,
        title: unchangedTitle,
        description: unchangedDescription
      })
    }, 100);
  }

  const updateChangesBeforeClosing = () => {
    if (location.state.path === '/statistics') {
      navigate(`${location.state?.path ? location.state.path : '/'}`, { state: { dateRange: location.state?.dateRange ? location.state.dateRange : null } }, { require: true })
    } else {
      var startTime = new Date().getTime()
      if (actionArray) {
        dispatch(timerPush({ taskId: taskData.id, timerIsActive: false, startTime: startTime }))
      }
      stopTimer(startTime)
      dispatch(addRunTime({ id: taskData.id, seconds: runTimeTask }))
      dispatch(editTodo({
        ...taskData,
        isOpened: date.toLocaleDateString() < taskDate ? taskData.isOpened : true,
        updatePageCalendar: location.state?.dateFrom && location.state.dateFrom
      })).then(() => {
        updateTaskList()
      })
      navigate(`${location.state?.path ? location.state.path : '/'}`, { state: { dateRange: location.state?.dateRange ? location.state.dateRange : null } }, { require: true })
    }
  }
  const updateWithoutSaving = () => {
    dispatch(addRunTime({ id: actionArray.taskId, seconds: taskData.runTime }));
    stopTimer(new Date().getTime())
  }

  const checkBeforePush = (hidebtn) => {
    if (hidebtn === ' unhideBtn-controls-to-modal') {
      setModalShowCheck(true);
    } else {
      updateChangesBeforeClosing();
    }
  }

  const checkLenghtOfTitle = (e) => {
    const unprivilegedEditor = reactQuillRef.current.unprivilegedEditor;
    if (unprivilegedEditor.getLength() < 100) {
      return setIsActiveCounter(false);
    } else if (unprivilegedEditor.getLength() === 100) {
      return setCurrentLenghtOfTitle(unprivilegedEditor.getLength()) & setIsActiveCounter(true);
    } else if ((unprivilegedEditor.getLength() <= maxLenghtOfTitle)) {
      return setCurrentLenghtOfTitle(unprivilegedEditor.getLength());
    } else if ((unprivilegedEditor.getLength() >= maxLenghtOfTitle && e.key !== 'Backspace')) {
      e.preventDefault()
    }
  }

  const objCheckList = {
    taskId: params.id,
    checkListTitle: checkListTitle,
  }

  const handleSubmitAddCheckListItem = (e) => {
    dispatch(addCheckList(objCheckList));
    setCheckListTitle('')
    e.preventDefault()
  }

  const resizeTagInput = (e) => {
    setWidthInputTag(e.target.scrollWidth + 'px');
    setHeightInputTag(e.target.scrollHeight + 'px');
  }

  const data = {
    warningText: 'Вы внесли изменения, но не сохранили их.',
    closeWarningFromProp: 'modalEditTask',
    changeFunctionProp: updateChangesBeforeClosing,
    stashProp: updateWithoutSaving
  }

  const updateTagList = (payload) => {
    setTaskData({
      ...taskData,
      tags: [...taskData.tags, payload]
    })
  }

  const removeTagFromTask = (value) => {
    setTaskData({
      ...taskData,
      tags: taskData.tags.filter((tag) => tag.tagId !== value)
    })
  }

  const updateDataDate = (payload) => {
    setTaskData({
      ...taskData,
      date: payload.date
    })
  }

  const updateIsOpened = (payload) => {
    setTaskData({
      ...taskData,
      isOpened: payload.isOpened
    })
  }

  const updateDataTime = (payload) => {
    setTaskData({
      ...taskData,
      time: payload.time
    })
  }

  const updateDataPriority = (payload) => {
    setTaskData({
      ...taskData,
      priority: payload.priority
    })
  }

  const updateRunTime = (payload) => {
    setRunTimeTask(payload.seconds)
  }

  const updateDataRunTime = (payload) => {
    setTaskData({
      ...taskData,
      runTime: payload
    })
  }

  const updateDataHours = (payload) => {
    setTaskData({
      ...taskData,
      hours: payload.hours
    })
  }

  const updateDataMinutes = (payload) => {
    setTaskData({
      ...taskData,
      minutes: payload.minutes
    })
  }

  useEffect(() => {
    function updateDataTitle() {
      return (
        setTaskData({
          ...taskData,
          title: title
        })
      )
    }

    updateDataTitle()
  }, [title]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    function updateDataDescription() {
      return (
        setTaskData({
          ...taskData,
          description: description
        })
      )
    }

    updateDataDescription()
  }, [description]) // eslint-disable-line react-hooks/exhaustive-deps

  const checkValueInputDate = (value) => {
    if (value === '') {
      return `Без срока  ${taskData?.time ? taskData.time : ''}`
    } else if (value === dateToday.toLocaleDateString()) {
      return `Сегодня ${taskData?.time ? taskData.time : ''}`
    } else if (value === dateTomorrow) {
      return `Завтра ${taskData?.time ? taskData.time : ''}`
    } else {
      return value
    }
  }
  console.log(taskDate);
  useEffect(() => {
    updateDataRunTime(runTimeTask)
  }, [runTimeTask]) // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal
      {...taskData}
      show={params.bool}
      onHide={() => checkBeforePush(hidebtn)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      title=''>
      {modalShowCheck ? <modalWindowContext.Provider value={[modalShowCheck, setModalShowCheck]}>
        <ModalWindowCloseWarning data={data} />
      </modalWindowContext.Provider>
        : null
      }
      <>
        <Modal.Header className="modal-header__change-task">
          <div style={{display: "flex", alignItems: "center"}}>
            {editTaskModalIcon}
            <p style={{ marginLeft: '5px', color: '#5932EA', fontSize: '14px' }}>
              {props.modalTitle ? props.modalTitle : null}
            </p>
          </div>
          <div ref={refCloseTaskMenu} style={{display: "flex", alignItems: "center"}}>
            {taskData?.transferCount > 0 &&
            <ToolTipComponent
              active={true}
              stylesList={stylesList}
              stylesListArrow={stylesListArrow}
              tooltipHeader= {taskData?.transferCount >= 3 ? 'Часто переносили задачу на другой день' : 'Сколько раз переносили задачу на другой день'}
              tooltipDescription= {taskData?.transferCount >= 3 ? `Прокрастинация — не выход, уделите этой задаче больше внимания` : 'Прокрастинация — не выход, отслеживайте, как часто переносите задачи на потом'}>
                <div ref={refTransfer} 
                  className="transferTaskCount" 
                  onMouseEnter={() => refTransfer.current.style.border = `${taskData?.transferCount >= 3 ? '1px solid #F85640' : '1px solid #A4A8B1'}`}
                  onMouseLeave={() => refTransfer.current.style.border = '1px solid transparent'}>
                  <TransferSVG style={{width: '12px', height: '12px', fill: taskData?.transferCount >= 3 ? '#F85640' : '#A4A8B1'}}/>
                  <p style={taskData?.transferCount >= 3 ? {color: '#F85640'} : {color: '#A4A8B1'}}>{taskData.transferCount}</p>
                </div>
            </ToolTipComponent>} 
            <span className="task-menu-btn" onClick={() => setMenuActivTask(true)}>
              <TaskKebabSVG className="taskKebabComponent" />
            </span>
            <DDSelectTaskMenu
              updateDataBeforeClose={updateChangesBeforeClosing}
              active={menuActivTask}
              setActive={setMenuActivTask}
              disabled={disabled ? disabled : null}
              fromRemoved={location.state?.fromRemoved && location.state.fromRemoved}
              fromCompleted={location.state?.fromCompleted && location.state.fromCompleted}
              taskId={taskData.id} />
            <span className="hide-btn" onClick={() => checkBeforePush(hidebtn)}>
              <HideBtnSVG className="hideBtnComponent" />
            </span>
          </div>

        </Modal.Header>
        <Modal.Body className="modal-body__change-task">
          <div className="modal-form__container">
            {
              loading ?
                <div style={windowScreen > 576 ? {
                  marginLeft: 'auto',
                  display: 'flex',
                  flexDirection: "column",
                  alignItems: 'center',
                  justifyContent: 'center'
                } :
                  {
                    display: "flex",
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    alignItems: 'center',
                    justifyContent: "center"
                  }}>
                  <Loader className='editTaskloader'></Loader>
                  <div className='footer-separator' style={{ margin: 0, visibility: "hidden" }} />
                </div> :
                <div className="description-task-container">
                  <div className={modalInputContainer} style={location.state?.fromCompleted || location.state.fromRemoved ? { pointerEvents: "none" } : null}
                    onFocus={() => setModalInputContainer('modal-form__input-container__focus')
                      & setHidebtn(' unhideBtn-controls-to-modal')}>
                    <ReactQuill
                      ref={reactQuillRef}
                      theme="bubble"
                      value={title}
                      id="title"
                      className={modalInputTitle}
                      placeholder="Название задачи"
                      onChange={setTitle}
                      autoFocus
                      onFocus={() => setDisablePriority(true)}
                      onKeyDown={checkLenghtOfTitle}
                    />
                    <ReactQuill
                      ref={reactQuillRefDescr}
                      theme="bubble"
                      value={description}
                      id="description"
                      modules={module}
                      className={modalInputDescr}
                      placeholder="Описание задачи"
                      onChange={setDescription}
                      onFocus={() => setDisablePriority(true)}
                    />
                  </div>
                  <div className={hidebtn}>
                    <div>
                      <p className={isActiveCounter ? 'alertCounterLengthOfTitleModal' : 'hide'}>
                        Лимит символов: {currentLenghtOfTitle} из 150
                      </p>
                    </div>
                    <button className="cancel-changes-form"
                      onClick={() => removeChanges()}>
                      Отмена
                    </button>
                    <button className="save-changes-form" disabled={formIsChanged}
                      onClick={() => setHidebtn('hideBtn-controls-to-modal')
                        & setDisablePriority(false)
                        & setFormIsChanged(true)
                        & setModalInputContainer('modal-form__input-container')
                        & setUnchangedTitle(title)
                        & setunchangedDescription(description)
                      }>
                      Сохранить
                    </button>
                  </div>
                  <div className='footer-separator' />
                  {
                    windowScreen > 576 ?
                      <div className="checklist-container" style={location.state?.fromCompleted || location.state.fromRemoved ? { pointerEvents: "none" } : null}>
                        <div className="checklist-box">
                          <div className="checklist-box__info">
                            <p className="checklist-box__title">Чек-лист</p>
                            <span className={controlBtnsCheckList ? 'checklist-box__list-arrow active-btn' : 'checklist-box__list-arrow'}
                              ref={refCloseControlBtnsCheckList}
                              onClick={() => setControlBtnsCheckList(!controlBtnsCheckList)} />
                          </div>

                          <DDSelectActionToCheckList active={controlBtnsCheckList}
                            setActive={setControlBtnsCheckList}
                            taskId={params.id} />

                          {params.id === undefined ?
                            null :
                            <CheckList taskId={params.id} />
                          }

                          {flag ?
                            <form onSubmit={(e) => handleSubmitAddCheckListItem(e)}
                              className="addchecklist">
                              <div className="addCheckElement" style={{ width: widthInputTag, height: heightInputTag }}>
                                <input type='text'
                                  id='checklist'
                                  name='cheklist'
                                  autoComplete="off"
                                  placeholder='Добавить пункт'
                                  value={checkListTitle}
                                  style={{ width: widthInputTag, height: heightInputTag }}
                                  onInput={(e) => resizeTagInput(e)}
                                  className='addCheckElement__input'
                                  onChange={e => setCheckListTitle(e.target.value)}
                                  autoFocus
                                  required />
                              </div>
                              <div className="addchecklist__control-btns">
                                <button className="addCheckElement__add-btn"
                                  onClick={(e) => handleSubmitAddCheckListItem(e)}
                                >Добавить</button>
                                <button
                                  className="close-add-task-form addCheckElement__close-btn"
                                  onClick={() => setFlag(false)}
                                >Отмена
                                </button>
                              </div>
                            </form>
                            :
                            <div >
                              <button className="addchekclist" onClick={() => setFlag(true)}>Добавить пункт</button>
                            </div>
                          }
                        </div>
                      </div> : null
                  }
                </div>
            }
            <div className="priority-container">
              <ul className="priority-list">
                <li className="priority-item" style={{ width: '100%' }}>
                  <div className="priority-time">
                    {
                      loading ? null :
                        <>
                          <div className="priority-time__container">
                            <TaskStopWatch updateRunTime={updateRunTime}
                              updateDataMinutes={updateDataMinutes}
                              timeTracking={settingsUser.isTimeTracking}
                              updateDataHours={updateDataHours}
                              updateIsOpened={updateIsOpened}
                              fromToday
                              taskIdModal={Number(params.id)}
                              modalShow={params.bool === 'true' ? true : false}
                              task={taskData}
                              loading={loading}
                              runTime={runTimeTask}
                              updateTaskFunc={getTask}
                              disabled={taskData.isCompleted || taskData.isDelete} />
                          </div>
                        </>
                    }
                    {/* {
                      loading ? null :
                        <>
                          {taskData.isOpened ? null :
                            <TaskCountDown
                              id={taskData.id}
                              task={taskData}
                              updateTask={updateTaskList}
                              updateIsOpened={updateIsOpened}
                              isOpened={taskData.isOpened}
                              disabled={disabled ? disabled : date.toLocaleDateString() < taskDate ? true : null} />
                          }
                        </>
                    } */}
                  </div>
                </li>
                <li className="priority-item">
                  <p className="modal-label">Срок выполнения</p>
                  {
                    loading ?
                      <Placeholder animation="wave">
                        <Placeholder xs={12} bg="light"></Placeholder>
                      </Placeholder> :
                      <button type="text"
                        id="priority-btn"
                        onClick={() => setMenuActivDatePicker(true)}
                        disabled={disabled ? disabled : disablePriority}
                        ref={refCloseDatePicker}
                        className="priority__input-modalwindow  priority__input-date-modal">
                        {checkValueInputDate(taskData.date)}
                        {/* {(dataInputDate[0] === '' ? 'Без срока' : dataInputDate.join(' '))} */}
                      </button>
                  }
                  {
                    menuActivDatePicker &&
                    <DDSelectDate active={menuActivDatePicker}
                      addTaskButtons
                      deadlineinput
                      styles={styles}
                      typeOfCalendar={1}
                      datePayload={ConverterUTC({method: "object", settingsUser: settingsUser, date: taskData.date})} 
                      time={dataInputDate[1]}
                      calendarPosition={windowScreen > 576 ? { right: '10px', top: '-34px' } : null}
                      setActive={setMenuActivDatePicker}
                      updateDataDate={updateDataDate}
                      updateDataTime={updateDataTime}
                      dataTask={taskData.date} />
                  }

                </li>
                <li className="priority-item">
                  <p className="modal-label">Приоритет</p>
                  {
                    loading ?
                      <Placeholder animation="wave">
                        <Placeholder xs={12} bg="light"></Placeholder>
                      </Placeholder> :
                      <button onClick={() => setMenuActivPriorityPicker(true)}
                        disabled={disabled ? disabled : disablePriority}
                        ref={refClosePriorityPicker}
                        className={classPriorityArray[taskData.priority]}>
                        {namesPriorityArray[taskData.priority]}
                      </button>
                  }
                  <DDSelectPriority active={menuActivPriorityPicker}
                    setActive={setMenuActivPriorityPicker}
                    priority={namesPriorityArray[taskData.priority]}
                    updateDataPriority={updateDataPriority} />
                </li>
                <li className="priority-item">
                  <p className="modal-label">Метки</p>
                  {
                    windowScreen > 576 ?
                      <button id='tags'
                        name='tags'
                        style={loading & windowScreen < 576 ? { display: "none" } : null}
                        className="priority__input-tags-modal priority__input-modalwindow"
                        disabled={loading ? true : disabled ? disabled : disablePriority}
                        ref={refCloseTagsPicker}
                        onClick={() => setMenuActivTagsPicker(true)}>
                        Добавить метку
                      </button> :
                      <div className="add-task__change-group__tags"
                        onClick={() => setMenuActivTagsPicker(true)}
                        ref={refCloseTagsPicker}
                        style={{
                          width: 'auto',
                          height: '34px',
                          fontSize: '10px',
                          borderRadius: '8px',
                          margin: 0,
                          color: '#232360',
                          border: menuActivTagsPicker ? '1px solid #5932EA' : null,
                          transition: 'border 0.3s ease-in-out'
                        }}>
                        <span className="add-task__add-tags-to-task"
                          style={{
                            width: '12px',
                            height: '12px',
                            margin: 0,
                            backgroundColor: taskData.tags.length === 0 ? '#A4A8B1' : '#5932EA'
                          }} />
                        <p style={{ marginLeft: '4px' }}>Метки</p>
                      </div>
                  }
                  <DDSelectTags active={menuActivTagsPicker}
                    setActive={setMenuActivTagsPicker}
                    taskId={params.id}
                    updateTagList={updateTagList}
                    tagList={taskData.tags}
                    removeTagFromTagList={removeTagFromTask}
                  />
                  {
                    loading ?
                      <Placeholder animation="wave">
                        <Placeholder xs={12} bg="light"></Placeholder>
                      </Placeholder> :
                      <ul className='test-block'>
                        {taskData.tags ? taskData.tags.map((el, index) =>
                          <li key={index} className="priority__tags-item" title={el.tagName}>

                            <p className="priority__tags-title">{el.tagName}</p>
                            <span className="priority__remove-tag"
                              onClick={() => removeTagFromTask(el.tagId)}
                            />
                          </li>) : null}
                      </ul>
                  }
                </li>
              </ul>
            </div>
            {
              windowScreen > 576 ? null :
                <div className="checklist-container" style={windowScreen > 576 ? { display: "none" } : { display: "block" }}>
                  <div className="checklist-box">
                    <div className="checklist-box__info">
                      <p className="checklist-box__title">Чек-лист</p>
                      <span className={controlBtnsCheckList ? 'checklist-box__list-arrow active-btn' : 'checklist-box__list-arrow'}
                        ref={refCloseControlBtnsCheckList}
                        onClick={() => setControlBtnsCheckList(!controlBtnsCheckList)} />
                    </div>

                    <DDSelectActionToCheckList active={controlBtnsCheckList}
                      setActive={setControlBtnsCheckList}
                      taskId={params.id} />

                    {params.id === undefined ?
                      null :
                      <CheckList taskId={params.id} />
                    }

                    {flag ?
                      <form onSubmit={(e) => handleSubmitAddCheckListItem(e)}
                        className="addchecklist">
                        <div className="addCheckElement" style={{ width: widthInputTag, height: heightInputTag }}>
                          <input type='text'
                            id='checklist'
                            name='cheklist'
                            autoComplete="off"
                            placeholder='Добавить пункт'
                            value={checkListTitle}
                            style={{ width: widthInputTag, height: heightInputTag }}
                            onInput={(e) => resizeTagInput(e)}
                            className='addCheckElement__input'
                            onChange={e => setCheckListTitle(e.target.value)}
                            autoFocus
                            required />
                        </div>
                        <div className="addchecklist__control-btns">
                          <button className="addCheckElement__add-btn"
                            onClick={(e) => handleSubmitAddCheckListItem(e)}
                          >Добавить</button>
                          <button
                            className="close-add-task-form addCheckElement__close-btn"
                            onClick={() => setFlag(false)}
                          >Отмена
                          </button>
                        </div>
                      </form>
                      :
                      <div >
                        <button className="addchekclist" onClick={() => setFlag(true)}>Добавить пункт</button>
                      </div>
                    }
                  </div>
                </div>

            }
          </div>
        </Modal.Body>
      </>
    </Modal>
  )
}

export default EditModalTask;