import React, { useState, useRef, useEffect } from "react";
import { regExpArray, regExpPriority, regExpTag } from "./data/regularExpForTime";
import { SmartRecognitionSetData } from "./SmartRecognitionSetData";
import MainTagsApp from "../DropDownMenu/SelectTags/MainTagsApp";

export interface SmartRecognitionProps {
    setDate: Function,
    setTime: Function,
    setTaskHours: Function,
    setTaskMinutes: Function,
    setTitle: Function,
    setPriority: Function,
    setAutoFocus: Function,
    checkLengthOfTitle: Function,
    unDisableBtnToAdd: Function,
    updateTagList: Function,
    tagList: Function,
    removeTagFromTagList: Function,
    arrMatching: string[],
    setArrMatching: Function
}

export default function SmartRecognitionV3({setTime, setDate, setTaskHours, setTaskMinutes, setPriority, setAutoFocus, updateTagList, tagList, removeTagFromTagList, setTitle} : SmartRecognitionProps) {
    const [recognitionMatches, setRecognitionMatches] = useState<string[]>([])
    const [untrackedMatches, setUntrackedMatches] = useState<string[]>([])
    const [isTagsListOpen, setIsTagListOpen] = useState<boolean>(false);
    const refSmartRecognition = useRef<(HTMLDivElement)>(null)
    console.log('Массив совпадений: ', recognitionMatches, 'Массив удаленных совпадений: ', untrackedMatches);
    
    useEffect(() => {
        SmartRecognitionSetData(recognitionMatches, setTime, setDate, setTaskHours, setTaskMinutes, setPriority, setIsTagListOpen)
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [recognitionMatches])

    useEffect(() => {
        let arr = []
        for (let index = 0; index < recognitionMatches.length; index++) {
            if (recognitionMatches[index].match(regExpPriority)) {
                arr.push(recognitionMatches[index])
            }
        }
        const arrWithoutLastMatch = arr.slice(0, arr.length - 1)
        for (let k = 0; k < arrWithoutLastMatch.length; k++) {
            const removeSpan = document.getElementById(`${arrWithoutLastMatch[k]}`)
                if (removeSpan) {
                    const spanContent = removeSpan?.innerText
                    const textNode = document.createTextNode(spanContent ?? '');
                    refSmartRecognition.current?.replaceChild(textNode,removeSpan)
                    setUntrackedMatches(prevState => [...prevState, spanContent])
                    setRecognitionMatches(recognitionMatches.filter(el => el !== spanContent))
                }
            console.log('Удаленный элемент DOM: ', removeSpan);
        }
        
    }, [untrackedMatches, recognitionMatches])    

    const inputHandler = (e: {target: HTMLDivElement}) => { 
        const text = e.target.textContent || ''
        setTitle(e.target.innerHTML || '')
        for (let i = 0; i < untrackedMatches.length; i++) {
            if (!text?.includes(untrackedMatches[i])) {
                setUntrackedMatches(untrackedMatches.filter(el => el !== untrackedMatches[i]))
            }
        }
        for (let i = 0; i < recognitionMatches.length; i++) {
            if (!text?.includes(recognitionMatches[i])) {
                setRecognitionMatches(recognitionMatches.filter(el => el !== recognitionMatches[i]))
            }
        }
                               
        for (const regexp of regExpArray) {                        
            let value = [...text.matchAll(regexp)]
            if (value.length !== 0) {
                value.map(el => {
                    if (untrackedMatches.find(i => i === el[0]) || recognitionMatches.find(i => i === el[0])) {
                        return 0
                    } else {
                        setRecognitionMatches(prevState => [...prevState, el[0] ?? ''])
                        addMatchingNode(refSmartRecognition.current as HTMLDivElement, el[0] ?? '');
                        return 0
                    }
                })
            }
        }
    }

    const keyHandler = (e: KeyboardEvent)  => {
        const spans = document.querySelectorAll("span[contenteditable]");            
        for (const span of spans) {
            if (span.contains(document?.getSelection()?.anchorNode)) {
                if (e.key === 'Backspace') {
                    if (!e.repeat) {
                        e.preventDefault()
                        const spanContent = span.innerHTML
                        if (regExpTag.test(spanContent)) {                            
                            setIsTagListOpen(false)
                        }
                        refSmartRecognition.current?.removeChild(span)
                        const textNode = document.createTextNode(spanContent ?? '');
                        refSmartRecognition.current?.appendChild(textNode);
                        moveCaretToEnd(refSmartRecognition.current as HTMLDivElement)
                        setRecognitionMatches(recognitionMatches.filter(el => el !== spanContent))
                        setUntrackedMatches(prevState => [...prevState, spanContent])
                    }
                } else if (e.keyCode === 32) {
                    const selectAllSpan = refSmartRecognition.current?.querySelectorAll("span")
                    const newDiv = document.createElement('div');
                    selectAllSpan?.forEach(((el: HTMLSpanElement) => {
                        if (el?.querySelector('div')) {
                            return
                        } else {
                            newDiv.id = el.id
                            newDiv.className = 'MatchedCloseBtn'
                            el.appendChild(newDiv)
                            el.setAttribute("contentEditable", "false")
                            moveCaretToEnd(refSmartRecognition.current as HTMLDivElement);
                        }
                    }))
                }
                }
            }
    }

    const removeSpanHandler = (e: {target: MouseEvent}) => {        
        const removeSpan = document.getElementById(e.target.id)
        removeSpan?.remove()
        moveCaretToEnd(refSmartRecognition.current as HTMLDivElement);
        setRecognitionMatches(recognitionMatches.filter(el => el !== e.target.id))
    }

    const moveCaretToEnd = (htmlElement: HTMLDivElement | HTMLSpanElement) => {
        const range = document.createRange();
        range.selectNodeContents(htmlElement);
        range.collapse(false);
        const selection = window.getSelection();
        selection?.removeAllRanges();
        selection?.addRange(range);
        htmlElement.focus();
        range.detach();
        addAllListeners();
    }

    const addMatchingNode = (parentElem: HTMLDivElement, matchedData: string) => {
        const newSpan = document.createElement('span');
        newSpan.className = 'MatchedText';
        newSpan.innerText = `${matchedData}`;
        newSpan.id = matchedData
        parentElem.innerHTML = parentElem.innerHTML.replace(matchedData, '')
        parentElem.append(newSpan)
        newSpan.setAttribute("contentEditable", "true")
        moveCaretToEnd(newSpan);
    }

    const addAllListeners = () => {
        const selectAllDiv = refSmartRecognition?.current?.querySelectorAll("div")
        selectAllDiv?.forEach((divEl: HTMLElement) =>{
            if (!divEl.hasAttribute('onClick')) {
                divEl.addEventListener('click', removeSpanHandler)
            }
        })    
    }

    return (
        <div>
            <div id="smartRecognition"
                contentEditable
                ref={refSmartRecognition}
                data-placeholder="Введите срок выполнения"
                className="add-task__input add-task__input-title"
                // onKeyDown={(e) => setTitle(e.target.innerText) & checkLenghtOfTitle(e.target.innerText) & unDisableButtonToPushTask()}
                onBlur={() => setAutoFocus(false)}
                onInput={(e) => inputHandler(e as any)}
                onKeyDown={(e) => keyHandler(e as any)}>
            </div>
            {
                isTagsListOpen &&
                    <div style={{position: 'absolute', zIndex: 1, maxWidth: '95%', maxHeight: '250px', height: '100%', overflow: "hidden", overflowY: 'scroll' ,backgroundColor: '#FFF',border: '1px solid #E8E8E8', borderRadius: '10px'}}>
                        <MainTagsApp 
                            updateTagList={updateTagList}
                            tagList={tagList}
                            removeTagFromTagList={removeTagFromTagList}
                        ></MainTagsApp>
                    </div>
            }
        </div>
    )
}
