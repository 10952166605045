import { regExpAfterTomorrow, regExpArray, regExpNoDate, regExpPriority, regExpTime, regExpTimeTask, regExpTomorrow } from "./data/regularExpForTime";
import { regExpToday } from "./data/regularExpForTime";


export function SmartRecognitionSetData(arrMatching: string[], setTime: Function, setDateTask: Function, setTaskHours: Function, setTaskMinutes: Function, setPriority: Function, setIsTagListOpen: Function) {
    console.log("Array from actions: ", arrMatching);
    
    const checkPriorityMatch = (value: string) => {
        if (value === '!!0') {
            return setPriority(3)
        } else if (value === '!!1') {
            return setPriority(0)
        } else if (value === '!!2') {
            return setPriority(1)
        } else if (value === '!!3') {
            return setPriority(2)
        }
    } 

    const text = arrMatching.join(' ')
        for (const regexp of regExpArray) {
            switch (regexp) {
                case regExpPriority:
                    let priority = text.match(regexp)
                    if (priority?.length) {
                        checkPriorityMatch(priority[0])
                    } else {
                        setPriority(3)
                    }   
                    break;
                case regExpToday:
                    const dateToday = new Date()
                    let todayDate = text.match(regexp)
                    if (todayDate?.length) {
                        setDateTask(dateToday.toLocaleDateString())
                    } else {
                        setDateTask(dateToday.toLocaleDateString())
                    }   
                    break;
                case regExpTomorrow:
                    const dateTomorrow = new Date()
                    let todayTomorrow = text.match(regexp)
                    if (todayTomorrow?.length) {
                        dateTomorrow.setDate(dateTomorrow.getDate() + 1).toLocaleString()
                        setDateTask(dateTomorrow.toLocaleDateString())
                    }
                    break;
                case regExpAfterTomorrow:
                    const dateAfterTomorrow = new Date()
                    let todayAfterTomorrow = text.match(regexp)
                    if (todayAfterTomorrow?.length) {
                        dateAfterTomorrow.setDate(dateAfterTomorrow.getDate() + 2)
                        setDateTask(dateAfterTomorrow.toLocaleDateString())
                    }
                    break;
                case regExpNoDate:
                    let todayNoDate = text.match(regexp)
                    if (todayNoDate?.length) {
                        console.log(todayNoDate);
                        setDateTask('')
                    } 
                    break;
                case regExpTime:
                    let time = text.match(regexp)
                    if (time?.length) {
                        let cuttedTime = time[0].split(' ')
                        setTime(cuttedTime[1])
                    } else {
                        setTime('')
                    }   
                    break;
                case regExpTimeTask:
                    let timeTask = text.match(regexp)
                    if (timeTask?.length) {
                        let cuttedTimeTask = timeTask[0].split(' ')
                        setTaskHours(cuttedTimeTask[1] ?? '')
                        setTaskMinutes(cuttedTimeTask[3] ?? '')
                    } else {
                        setTaskHours('')
                        setTaskMinutes('')
                    }   
                    break;
                default:
                    break;
            }
        }
}